<template>
  <div class="dashboard-page">
    <template v-if="this.$store.state.user.service_client.name=='SmartCoachPro'">
      <proDashboard></proDashboard>
    </template>
    <template v-else>
      <particularDashboard 
        v-for="(subscription, index) in this.$store.state.user.subscriptions"
        :subscription="subscription" 
        :key="index" 
        :ident="index"
      >
      </particularDashboard>
    </template>

  </div>
</template>

<script>

  import particularDashboard from "./dashboards/particularDashboard";
  import proDashboard from "./dashboards/proDashboard";

  export default {
    name: 'Dashboard',

    components:{
      particularDashboard,
      proDashboard,
    },

    computed: {
    },



  }
</script>