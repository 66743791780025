import i18n from '../../utils/i18n';


const state = {
    lang: 'fr',
};

const mutations = {
    updateLang: (state, lang) => {
        state.lang = lang;
        i18n.locale = lang;
    },
};

const actions = {
    updateLang({ commit }, lang) {
        localStorage.setItem('lang', lang);
        commit('updateLang', lang);
        //if lang change => execute get user informations ws => updateUser;
      },
  
      setLang({ commit }){
        commit('updateLang', localStorage.getItem('lang'));
      }
};

export default {
    state,
    actions,
    mutations
  }