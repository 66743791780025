<template>
  <div>
  <div class="bg-page" :style="showCookiesContent ? showStyle : hideStyle"></div>
  <div id="cookies-container" :style="showCookiesContent ? showStyle : hideStyle">
    <div class="cookies-container-content">
      <h1 class="center">{{ $t('_cookies_title')}}</h1>
      <p v-html="$t('_cookies_description')"></p>
      <h2>{{ $t('_functionality_cookies')}}</h2>
      <p v-html="$t('_functionality_cookies_description')"></p>
      <h2>{{ $t('_recaptcha_cookies')}}</h2>
      <p v-html="$t('_recaptcha_cookies_description')"></p>

      <h2>{{ $t('_audience_cookies')}}</h2>
      <p v-html="$t('_audience_cookies_description')"></p>
      <div class="textAlignRight">
            <button 
              class="btn btn-lg rounded btn-suez-green-bordred border-radius m-2"  
              :class="($store.state.user.cookie && $store.state.user.cookie.audience == true) ? 'btn-suez-green-bordred-active' : ''"
              @click="acceptCoockies(true)">
              <span>{{ $t('_accept') }}</span>
            </button>

            <button 
              class="btn btn-lg rounded btn-suez-red-bordred border-radius m-2" 
              :class="($store.state.user.cookie && $store.state.user.cookie.audience === false) ? 'btn-suez-red-bordred-active' : ''" 
              @click="acceptCoockies(false)"
            >
              <span>{{ $t('_refuse') }}</span>
            </button>
      </div>
    </div>
    <div class="textAlignRight">

      <button 
        class="btn btn-lg rounded btn-suez-dark-light-blue border-radius m-2"  
        @click="hideCookies()">
        <span>{{ $t('_close') }}</span>
      </button>

    </div>
  </div>
  </div>
</template>


<script>
import api from "../../utils/api";
export default {

  name: 'Cookies',

  data () {
      return {
        showStyle: { 
            display: 'inherit'
        },
        hideStyle: {
            display: 'none'
        },
      }
  },

  props: {
    showCookiesContent: { type: Boolean},
  },


  methods: {

      acceptCoockies: function (myChoice) {
          let result = {
              audience: myChoice
          };
          api.saveCookies(result).then((data) => {
            this.$store.dispatch('updateUser', data);
          }).catch((err) => {
            console.log(err); 
          });

          this.hideCookies();
      },

      hideCookies: function() {

        this.$emit('hide-cookies-content');

      }


  },
}
</script>