<template>
        <div class='account-not-found-popup'>
            <p><b>{{pds}} :</b> {{this.$i18n.t('_account_not_found')}}</p>
        </div>
</template>

<script>

export default {

    props:{
        pds: {required: true}
    },

}
</script>