<template>
<div>
  <header-app></header-app>
    <vertical-menu 
      :subscriptions="this.$store.state.user.subscriptions" 
      :services="this.$store.state.user.services" 
      :pages="getPagesByLocation('top')"   
      >
    </vertical-menu>

    <div class="main-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
        
        <div class="main-page-content informations-page">
          <controls></controls>
       
          <div class="header-page">
              <h1 class="floatLeft bold upper">{{this.$i18n.t('_contact')}} </h1>
          </div> 

          <div v-if='this.$store.state.user.client.contacts_infos.phoneNumberBlock==true' class="blockInformation block-m-height block-x1 frame-content-left margin-bottom-20">
              <div class="frame-top-content"> 
                {{this.$store.state.user.client.contacts_infos.phoneNumberTitle}} 
              </div>
              <div class="informations">
                  <div class="informations-item">
                      <span class="informations-item-title">{{this.$i18n.t('_schedule')}} :</span> {{ this.$store.state.user.client.contacts_infos.phoneNumberSchedule}}
                  </div>
                  <div class="informations-item">
                     <span class="informations-item-title">{{this.$i18n.t('_phone_number')}} :</span> {{ this.$store.state.user.client.contacts_infos.phoneNumber}}
                  </div>
              </div>
          </div>

          <div v-if='this.$store.state.user.client.contacts_infos.emergencyServiceBlock==true' 
            class="blockInformation block-m-height block-x1 frame-content-left margin-bottom-20" 
            :class="this.$store.state.user.client.contacts_infos.phoneNumberBlock ? 'margin-left-2' : ''"
          >
              <div class="frame-top-content"> 
                {{this.$store.state.user.client.contacts_infos.emergencyPhoneNumberTitle}} 
              </div>
              <div class="informations">
                  <div class="informations-item">
                      <span class="informations-item-title">{{this.$i18n.t('_schedule')}} :</span> {{ this.$store.state.user.client.contacts_infos.emergencyPhoneNumberSchedule}}
                  </div>
                  <div class="informations-item">
                     <span class="informations-item-title">{{this.$i18n.t('_phone_number')}} :</span> {{ this.$store.state.user.client.contacts_infos.emergencyPhoneNumber}}
                  </div>
              </div>
          </div>



          <div v-if='this.$store.state.user.client.contacts_infos.emailBlock==true' 
            class="blockInformation block-x1 frame-content-left margin-bottom-20 height-auto" 
            :class="this.$store.state.user.client.contacts_infos.phoneNumberBlock && !this.$store.state.user.client.contacts_infos.emergencyServiceBlock ||  !this.$store.state.user.client.contacts_infos.phoneNumberBlock && this.$store.state.user.client.contacts_infos.emergencyServiceBlock ? 'margin-left-2' : ''"
          >
              <div class="frame-top-content"> 
                {{this.$i18n.t('_courriel')}} 
              </div>
              <form @submit.prevent="sendEmail">
                <div class="informations">
                    <div v-if='this.$store.state.user.client.contacts_infos.emailObject' class="informations-item">
                        <span class="informations-item-title">{{this.$i18n.t('_subject')}} :</span> 
                        <input required type="text" name="subject" v-model="contactForm['subject']" class="form-control form-control-lg"/>
                    </div>
                    <div v-if="this.$store.state.user.client.contacts_infos.emailMessage" class="informations-item">
                        <span class="informations-item-title">{{this.$i18n.t('_message')}} :</span> 
                        <textarea required v-model="contactForm['message']" name="message" class="form-control form-control-l"></textarea>
                    </div>

                    <div class="center" v-if="loading">
                        <div class="spinner-border m-2" role="status"></div>
                    </div>
                    <div v-if="success" class="alert alert-success float-left width100" role="alert">
                        {{ this.$i18n.t('_message_sent') }}
                    </div>
                    <div v-if="success===false" class="alert alert-danger float-left width100" role="alert">
                        {{ this.$i18n.t('_error_message_sent') }}
                    </div>

                    <div  class="informations-item">
                        <input type="submit" class="btn btn-suez-blue btn-lg form-control" :value="this.$i18n.t('_send')" />
                    </div>
                </div>
              </form>
          </div>

          <div v-if='this.$store.state.user.client.contacts_infos.emailPostalBlock==true' 
            class="blockInformation block-x1 frame-content-left margin-bottom-20 height-auto" 
            :class="this.$store.state.user.client.contacts_infos.phoneNumberBlock && !this.$store.state.user.client.contacts_infos.emergencyServiceBlock && !this.$store.state.user.client.contacts_infos.emailBlock || 
            !this.$store.state.user.client.contacts_infos.phoneNumberBlock && this.$store.state.user.client.contacts_infos.emergencyServiceBlock && !this.$store.state.user.client.contacts_infos.emailBlock || 
            !this.$store.state.user.client.contacts_infos.phoneNumberBlock && !this.$store.state.user.client.contacts_infos.emergencyServiceBlock && this.$store.state.user.client.contacts_infos.emailBlock || 
            this.$store.state.user.client.contacts_infos.phoneNumberBlock && this.$store.state.user.client.contacts_infos.emergencyServiceBlock && this.$store.state.user.client.contacts_infos.emailBlock 

            ? 'margin-left-2' : ''"
          >
              <div class="frame-top-content"> 
                {{this.$i18n.t('_postal_mail')}} 
              </div>
              <div class="informations">
                  <div class="informations-item">
                     <span class="informations-item-title">{{this.$i18n.t('_address')}} :</span> {{ this.$store.state.user.client.contacts_infos.emailPostalAddress }}
                  </div>
              </div>
          </div>

        </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')">
    </footerApp>

</div>
</template>


<script>
import {mapGetters} from "vuex";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";
import controls from "../functionnals/controls";
import api from "../../utils/api";

export default {
    name: 'PersonalInformations',
    components:{
        headerApp,
        verticalMenu,
        footerApp,
        controls
    },

    data() {
        return {
            contactForm: {},
            loading: false,
            success: null
        };
    },


    computed: {
        ...mapGetters([
          'getPagesByLocation'
          ]),
    },

    methods: {
        sendEmail(){

            this.loading = true;
            this.success = null;
            api.contactSendMessage(this.contactForm)
            .then(() => {
                this.loading = false;
                this.success = true;
            })
            .catch(() => {
                this.loading = false;
                this.success = false;
            });
        }
    }

  }
</script>

