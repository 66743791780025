<template>
  <div class="float-left width100">
    
    <ProfessionalProfileWidget 
      :questionsUrl="questionsUrl" 
      :urlWidgetProfile="urlWidgetProfile"
      :sid="sid" 
      :urlSavingProfileAnswers="urlSavingProfileAnswers"
      :dataConsumptionUrlCallBack="dataConsumptionUrlCallBack"
      :dataDashboardUrlCallBack="dataDashboardUrlCallBack"
      >
    </ProfessionalProfileWidget>


  </div>
</template>

<script>
import ProfessionalProfileWidget from "../../widgets/professionalProfile";

  export default {
    name: 'ProfessionalProfile',
    components:{
        ProfessionalProfileWidget,
    },
    props: {
        questionsUrl: {type: String, required: true},
        urlWidgetProfile: {type: String, required: true},
        sid: {type: Number, required: true},
        urlSavingProfileAnswers: {type: String, required: true},
        dataConsumptionUrlCallBack: {type: String, required: false},
        dataDashboardUrlCallBack: {type: String, required: false},
    }
  }
</script>