<template>
<div>
    <header-app></header-app>
    <vertical-menu 
      :subscriptions="this.$store.state.user.subscriptions" 
      :services="this.$store.state.user.services" 
      :pages="getPagesByLocation('top')"   
      >
    </vertical-menu>

    <div class="main-page my-consumption-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
        
        <div class="main-page-content">
          <controls></controls>
          
          <div class="header-page">
            <h1 class="floatLeft bold upper">{{this.$i18n.t('_my_consumption')}}</h1>
          </div> 

          <template v-for="(otherSubscription, index) in this.$store.state.user.subscriptions">

                <ProfileWidgetLink 
                  v-if="otherSubscription.id==subscriptionId"
                  type="subscriptions" 
                  :subscription="otherSubscription" 
                  :key="'subscriptions' + otherSubscription.id"
                  ></ProfileWidgetLink>
                <ProfileWidgetLink 
                  v-else
                  type="subscription" 
                  :subscription="otherSubscription"  
                  :key="'subscription' + otherSubscription.id"
                  ></ProfileWidgetLink>

                <template v-if='subscription && otherSubscription.id == subscription.id'>
                  <div :key="index" v-if="accountNotFound">
                      <accountNotFoundModal :pds="subscription.pds"></accountNotFoundModal >
                  </div>
                  <div :key="index" v-else>
                    <div :key="index" v-if="subscription.activated && !showActivationModal">
                      <spinner :key="index"></spinner>
                      <template v-if="widget && showWidget" >
                        <keep-alive :key="index">
                          <smartCoachPro :key="'smartCoachPro' + index" v-if="widget.name ==='SmartCoachPro'" :widget="widget" :widgetParams="widgetParams"></smartCoachPro>
                          <smartCoach :key="'smartCoach' + index" v-if="widget.name ==='SmartCoach'" :widget="widget" :widgetParams="widgetParams"></smartCoach>
                        </keep-alive>
                      </template>
                    </div>
                    <div :key="index" v-else>
                      <activationModal :subscription="subscription"></activationModal>
                    </div>
                  </div>
                </template>
          </template>    
    
        </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')">
    </footerApp>

</div>
</template>



<script>
import {mapGetters} from "vuex";
import api from "../../utils/api";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";
import activationModal from "../functionnals/activationModal";
import accountNotFoundModal from "../functionnals/accountNotFoundModal";
import spinner from "../functionnals/spinner";
import smartCoachPro from "../widgets/smartCoachPro";
import smartCoach from "../widgets/smartCoach";
import ProfileWidgetLink from "../functionnals/profileWidgetLink";
import controls from "../functionnals/controls";

export default {
    name: 'Subscription',
    components:{
        headerApp,
        verticalMenu,
        footerApp,
        activationModal,
        accountNotFoundModal,
        spinner,
        smartCoachPro,
        smartCoach,
        ProfileWidgetLink,
        controls
    },

    props: {
        client: {type: String, required: true},
        subscriptionId: {required: true}
    },

    data: function(){
        return {
            widget: null,
            showWidget: false,
            subscription: null,
            showActivationModal: false,
            apiErrorMessage: null,
            widgetParams: [],
            accountNotFound: false,
        }
    },

    computed: {
        ...mapGetters([
          'getPagesByLocation',
          'getSubscriptionById',
          'getSubscriptionsWithoutConsents'
        ]),
        
    },

    mounted(){
      
      
      if(this.$store.state.fromPath != "/" && this.$store.state.fromPath != this.$route.path){
        this.$router.go();
      }else{
        this.widgetParams = this.$store.state.user.service_client.params;
        this.subscription = this.getSubscriptionById(this.subscriptionId);
        this.selectSubscription(this.subscription);
      }

    },
    
    methods: {
      
      //get widget
      selectSubscription(subscription){
        this.initialize();
        this.subscription = subscription;

        api.getWidget(subscription).then((data) => {
            this.showWidget = true;
            this.widget = data;
            if(data.token == 'invalid' ){
              this.accountNotFound = true
            }
        }).catch((status) => {
          if(status == 404){
              this.showActivationModal = true;
          }else{
                this.false = true;
                this.apiErrorMessage = this.$i18n.t('_server_error');
          }             
        });

      },

      initialize: function(){
        this.widget = null;
        this.showWidget = false;
        this.subscription = null;
        this.showActivationModal = false;
        this.apiErrorMessage= null;
      }
    },
  }
</script>

