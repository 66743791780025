<template>
  <div class='container-fluid auth-page login-page'>
    <div class="row">
      <div class="col-md-11 offset-md-1  d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-5 col-md-7">
                    <!-- form card login -->
                    <div class="card rounded shadow shadow-sm">
                      
                       <div class="card-header">
                            <img src="../../assets/onConnectLogo.png">
                        </div>

                        <div class="card-body">
                          <h4 class="mb-0">{{ this.$i18n.t('connection-panel') }}</h4>
                          <br>
                          <form class="form" @submit.prevent="handleSubmit">
                              <div class="form-group">
                                  <label for="email">{{ this.$i18n.t('E-mail') }}</label>
                                  <input :placeholder="this.$i18n.t('enter-email')" type="email" class="form-control form-control-lg rounded-0" v-model="email" name="email" required="true">
                              </div>
                              <div class="form-group">
                                  <label>{{ this.$i18n.t('password') }}</label>
                                  <input :placeholder="this.$i18n.t('enter-password')" type="password" class="form-control form-control-lg rounded-0" v-model="password" name="password" required="true">
                              </div>

                              <div v-if="apiHasError">
                                <b-alert variant="danger" show>
                                    {{apiErrorMessage}}
                                </b-alert>
                              </div>
                              
                              <spinner></spinner>

                              <button class="btn btn-secondary btn-lg form-control">{{ this.$i18n.t('login') }}</button>
                              <div>
                                <br>
                                <label>
                                <router-link to="forgotten-password">
                                  <span class="custom-control-description small text-dark">{{ this.$i18n.t('forgot-password') }}</span>
                                </router-link>
                                </label>
                              </div>
                          </form>
                        </div>
                        <!--/card-block-->
                    </div>
                    <!-- /form card login -->
                </div>
            </div>
            <!--/row-->
        </div>
    </div>
  </div>
</template>


<script>

import api from "../../utils/api";
import Utils from "../../utils/Util";
import spinner from "../functionnals/spinner";

export default {

  name: 'Login',

  components:{
      spinner
  },

  data: function () {
    return {
      email: '',
      password: '',
      apiHasError: false,
      apiErrorMessage: null,
      utils: new Utils()
    }
  },

  methods: {
    handleSubmit () {
      const { email, password } = this;
      if (email && password) {
        this.apiHasError = false;
        this.apiErrorMessage = null;
        
        api.dologin(email, password).then((data) => {   
            this.$store.dispatch('saveToken', data.token);
            this.$store.dispatch('saveRefreshToken', data.refresh_token);
            var lang = this.utils.getLanguage(navigator.language.split('-')[0]);
            this.$store.dispatch('updateLang', lang);
            //get user infos
            this.getUserInformations();
        }).catch((err) => {
            if(err == "401"){
              this.apiErrorMessage = this.$i18n.t('_invalid_credentials');
            }else{
              this.apiErrorMessage = this.$i18n.t('_server_error');
            }
            this.apiHasError = true;
        });
      }
    },

    getUserInformations: function(){

        this.apiHasError = false;
        this.apiErrorMessage = null;

        api.getUser().then((data) => {
            this.$store.dispatch('setLoading', false);
            this.$store.dispatch('setUser', data);
        }).catch(() => {
          this.$store.dispatch('setLoading', false);
          this.apiErrorMessage = this.$i18n.t('_server_error');
          this.apiHasError = true;
        });

    }
  }

}
</script>
