import store from '../store';
import availableLanguages from './availableLanguages';

export default class Utils {

    formatDate(date){
        var tabDate = date.split('-');
        var newDate = new Date(tabDate[2], tabDate[0]-1, tabDate[1]);
        return (newDate.toLocaleDateString(store.state.lang.lang));
    }


    formatDateForLogin(date){
        
        var tabDate = date.split('-');
        return(tabDate[2]+"-"+tabDate[0]+"-"+tabDate[1]);
    }

    getLanguage(lang){

        if (!availableLanguages.langs.includes(lang)){
            return availableLanguages.defaultLang;
        }

        return lang;

    }

    isCookiesAccepted(){
        if(store.state.user.cookie)
            return  store.state.user.cookie.audience;
        
            return null;
    }

}

