<template>
<div>
  <header-app></header-app>
  <vertical-menu 
    :subscriptions="this.$store.state.user.subscriptions" 
    :services="this.$store.state.user.services" 
    :pages="getPagesByLocation('top')"   
    >
  </vertical-menu>

    <div class="main-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
        
        <div class="main-page-content">
          <controls></controls>
          
          <div class="header-page">
              <h1 class="floatLeft bold upper">{{this.$i18n.t('_cancellation')}}</h1>
          </div> 

          <div class="information-panel">
            <div class="block-information-confirmation mx-auto p-5 clearfix">
              <h5><b>{{this.$i18n.t('_cancellation_title')}}</b></h5>
              <p>{{this.$i18n.t('_cancellation_description')}}</p>     
              <spinner></spinner>
              
              <div class="col-md-6 col-6 offset-3">
                  <div class="btn btn-lg rounded btn-suez-blue m-2 border-radius" @click="terminate()">{{ $t('_yes') }}</div>
                  <router-link :to="{ name: 'personalInformations'}">
                    <div class="btn btn-lg rounded btn-suez-dark-light-blue">{{ $t('_no') }}</div>
                  </router-link>
              </div>
            </div>
          </div>  

          <div v-if="apiErrorMessage" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger center width100 float-left">
              {{ apiErrorMessage }}
          </div>
      </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')">
    </footerApp>

</div>
</template>
    


<script>
import {mapGetters} from "vuex";
import api from "../../utils/api";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";
import spinner from "../functionnals/spinner";
import controls from "../functionnals/controls";

export default {
    name: 'Cancellation',
    components:{
        headerApp,
        verticalMenu,
        footerApp,
        spinner,
        controls
    },

    props: {
    },

    data: function(){
        return {
          apiErrorMessage: null
        }
    },

    computed: {
        ...mapGetters([
          'getPagesByLocation',
          'getSubscriptionsWithoutConsents',
        ]),
    },

    methods: {

      terminate: function(){

          api.terminate().then(() => {
              this.$router.push('/logout');
          }).catch(() => {
            this.apiErrorMessage = this.$i18n.t('_server_error');          
          });

      }

    },
  }
</script>

