import Vue from 'vue'
import Router from "vue-router"
import Login from '../components/auth/Login';
import ResetPassword from '../components/auth/ResetPassword';
import ForgottenPassword from '../components/auth/ForgottenPassword';
import ChangePassword from '../components/auth/ChangePassword';
import PageNotFound from '../components/pages/PageNotFound';
import Home from '../components/pages/Home';
import PersonalInformations from '../components/pages/PersonalInformations';
import Subscriptions from '../components/pages/Subscriptions';
import Cancellation from '../components/pages/Cancellation';
import Meter from '../components/pages/Meter';
import Subscription from '../components/pages/Subscription';
import SinglePage from '../components/pages/SinglePage';
import Profile from '../components/pages/Profile';
import Alerts from '../components/pages/Alerts';
import Contact from '../components/pages/Contact';
import store from '../store';
import api from "./api";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', component: Login },
        { path: '/login', component: Login },
        {
            path: '/reset',
            component: ResetPassword,
            name: 'resetPassword',
            props: (route) => ({ token: route.query.token })
        },
        {
            path: '/forgotten-password',
            component: ForgottenPassword,
            name: 'forgottenPassword'
        },
        {
            path: '/logout',
            component: Login,
            beforeEnter: (to, from, next) => {
                store.dispatch('logout');
                return next('/login');
            }
        },
        { path: '/:client/personal-informations', component: PersonalInformations, name: 'personalInformations', meta: { global: 'personalInfos' } },
        { path: '/:client/personal-informations/change-password', component: ChangePassword, name: 'changePassword', meta: { global: 'personalInfos' } },
        { path: '/:client/personal-informations/meter', component: Meter, name: 'meter', props: { meta: 'personalInfos' } },
        { path: '/:client/personal-informations/cancellation', component: Cancellation, name: 'cancellation', props: { meta: 'personalInfos' } },
        { path: '/:client/subscriptions', component: Subscriptions, name: 'subscriptions', props: true, meta: { global: 'subscriptions' } },
        { path: '/:client/subscriptions/profile/:subscriptionId', component: Profile, name: 'profile', props: true, meta: { global: 'subscriptions' } },
        { path: '/:client/subscriptions/details/:subscriptionId', component: Subscription, name: 'subscription', props: true, meta: { global: 'subscriptions' } },
        { path: '/:client/home', component: Home, name: 'home', meta: { global: 'home' } },
        { path: '/:client/page/:pageId', component: SinglePage, name: 'singlePage', props: true },
        { path: '*', component: PageNotFound, name: 'pageNotFound' },
        { path: '/:client/alerts', component: Alerts, name: 'alerts', props: true, meta: { global: 'alerts' } },
        { path: '/:client/contact', component: Contact, name: 'contact', meta: { global: 'contact' } },

    ]
});

router.beforeEach((to, from, next) => {

    store.dispatch('fetchAccessToken');
    store.dispatch('fetchRefreshToken');
    store.dispatch('fetchUser');
    store.dispatch('storeFromPath', from.fullPath);

    const user = store.state.user;

    if(user){
        window._paq.push(['setCustomVariable',
              1,
              "Email",
              user.email,
              "visit"
          ]);
    }
    

    if(to.query.from && to.query.from == 'profile' || store.state.refresh == true){
        api.getUser().then((data) => {
            store.dispatch('updateUser', data);
        }).catch(() => {});
        store.dispatch('refresh', false);
    }

    if (store.state.accessToken && store.state.user) {
        store.dispatch('setLang');
    }
    

    if (to.path == '/reset') {
        store.dispatch('deleteSession');
        return next();
    }

    if (to.path !== '/login' && to.path !== '/' && to.path != '/reset' && to.path != '/forgotten-password') { // if not login page : check if connected
        if (!store.state.accessToken || !store.state.user) {
            return next('/login');
        }
    }

    if (to.path === '/login' || to.path === '/' || to.path === '/reset' || to.path === '/forgotten-password') { // if login page requested check if connected
        if (store.state.accessToken && store.state.user) {
            return next('/' + user.client.name + '/home');
        }
    }
    return next();

});

router.afterEach((to) => {

    if (to.name == "profile") {
        store.dispatch('refresh', true);
    }
});

export default router;