<template>
  <div class='container-fluid auth-page reset-password-page'>
    <div class="row">
      <div class="col-md-11 offset-md-1  d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-5 col-md-7">
                    <div class="card rounded shadow shadow-sm">
                       <div class="card-header">
                            <img src="../../assets/onConnectLogo.png">
                        </div>
                        <div class="card-body">
                          <h4 class="mb-0">{{ this.$i18n.t('_reset_panel') }}</h4>
                          <br>
                          <spinner></spinner>
                          <div v-if="!isSent && isValidToken===true">
                            <form class="form" @submit.prevent="handleSubmit">
                                <div class="form-group">
                                    <label>{{ this.$i18n.t('password') }}</label>
                                    <input :placeholder="this.$i18n.t('enter-password')" type="password" class="form-control form-control-lg rounded-0" v-model="password" required="true">
                                </div>
                                <div class="form-group">
                                    <label>{{ this.$i18n.t('_password_verification') }}</label>
                                    <input :placeholder="this.$i18n.t('enter-password')" type="password" class="form-control form-control-lg rounded-0" v-model="passwordValidation" required="true">
                                </div>
                                <div class="form-group" v-if="!match">
                                    <b-alert variant="danger" show>
                                        {{ this.$i18n.t('_passwords_not_match') }}
                                    </b-alert>
                                </div>
                                <div v-if="apiHasError">
                                    <br>
                                    <b-alert variant="danger" show v-html="apiErrorMessage"></b-alert>
                                </div>                               
                                <button class="btn btn-secondary btn-lg form-control">{{ this.$i18n.t('_validate') }}</button>
                            </form>
                          </div>

                          <div v-if="isValidToken === false">
                                <b-alert variant="danger" show>
                                    {{ this.$i18n.t('_token_not_found') }}
                                </b-alert>
                            </div>
                          <div v-if="isSent">
                            <b-alert variant="success" show>
                                {{ this.$i18n.t('_request_is_sent') }}
                            </b-alert>
                        </div>
                         
                            <br>
                            <label>
                                <router-link to="login">
                                    <span class="custom-control-description small text-dark">{{ this.$i18n.t('login') }} ?</span>
                                </router-link>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import api from "../../utils/api";
import spinner from "../functionnals/spinner";
export default {

    name: 'ResetPassword',

    components:{
        spinner
    },

    props: {
        token: {required: true},
    },

    data: function () {
        return {
            password: '',
            passwordValidation: '',
            match: true,
            isSent: false,
            isValidToken: null,
            apiHasError: false
        }
    },

    mounted() {
        //verify token
        api.verifyTokenInitialisation(this.token).then(() => {
            this.isValidToken = true;
        }).catch(() => {
            this.isValidToken = false;
        });

    },

    methods: {
        
        handleSubmit () {
            const { password, passwordValidation} = this;
            this.apiHasError = false;
            if (passwordValidation && password) {
                if(passwordValidation != password){
                    this.match = false;
                }else{
                    this.match = true;
                    api.resetPassword(this.token, password, passwordValidation).then(() => {
                        this.apiHasError = false;
                        this.isSent = true;
                    }).catch((status) => {
                        if(status == 400){
                            this.apiErrorMessage = this.$i18n.t('_check_passwords');
                        }else{
                            this.apiErrorMessage = this.$i18n.t('_server_error');
                        }
                        this.apiHasError = true;
                    });
                    
                }
            }
        }
    }
}
</script>
