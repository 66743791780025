<template>
<div>
  <header-app></header-app>
    <vertical-menu 
      :subscriptions="this.$store.state.user.subscriptions" 
      :services="this.$store.state.user.services" 
      :pages="getPagesByLocation('top')"   
      >
    </vertical-menu>

    <div class="main-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
        
        <div class="main-page-content informations-page">
          <controls></controls>
       
          <div class="header-page">
              <h1 class="floatLeft bold upper">{{this.$i18n.t('_my_personal_informations')}} </h1>
          </div> 
        
          <div class="blockInformation block-m-height block-x1 frame-content-left">
              <div class="frame-top-content"> 
                {{this.$i18n.t('_identifiers')}} 
                <div class="informations-item-right edit-btn">
                    <router-link :to="{ name: 'changePassword'}">
                       <span class="edit-icon"></span>
                    </router-link>
                </div>
              </div>
              <div class="informations">
                  <div class="informations-item">
                      <span class="informations-item-title">{{this.$i18n.t('_email_address')}} :</span> {{ this.$store.state.user.email}}
                  </div>
                  <div class="informations-item">
                     <span class="informations-item-title">{{this.$i18n.t('password')}} :</span> *****************
                  </div>
              </div>
          </div>

          <div class="blockInformation block-m-height block-x1 frame-content-left margin-left-2">
              <div class="frame-top-content"> {{this.$i18n.t('_my_contract')}} </div>
              <div class="informations">
                  <div class="informations-item">
                      <span class="informations-item-title">{{this.$i18n.t('_client_name')}} :</span> {{ this.$store.state.user.client.name}}
                  </div>
                  <div class="informations-item">
                      <span class="informations-item-title">{{this.$i18n.t('_username')}} :</span> {{ this.$store.state.user.lastName}}
                  </div>
                  <div class="informations-item">
                       <span class="informations-item-title">{{this.$i18n.t('_user_firstname')}} :</span> {{ this.$store.state.user.firstName}}
                  </div>
              </div>
          </div>

          <div class="blockInformation block-m-height block-x1 frame-content-left margin-top-20">
              <div v-if="this.$store.state.user.subscriptions.length > 1" class="frame-top-content"> 
                {{this.$i18n.t('_my_meters')}} 
                <div class="informations-item-right edit-btn">
                  <router-link :to="{ name: 'meter'}"> 
                      <span class="edit-icon"></span>
                  </router-link>
                </div>
              </div>
              <div v-else class="frame-top-content"> 
                {{this.$i18n.t('_my_meter')}}  
                <div class="informations-item-right edit-btn">
                  <router-link :to="{ name: 'meter'}">
                    <span class="edit-icon"></span>
                  </router-link>
                </div>
              </div>
              
              <div class="informations " :class="(this.$store.state.user.subscriptions.length >= 2 ? 'myCounters' : '')">
                  <div class="informations-item" v-for="(subscription, index) in this.$store.state.user.subscriptions" :key="index">
                    <div class="informations-item-left">
                      <div class="informations-item">
                        <span class="informations-item-title">{{$t('_number')}} :</span> {{subscription.pds}}
                      </div>  
                      <div class="informations-item">  
                        <span class="informations-item-title">{{$t('_name')}} :</span> {{subscription.pdsname | defaultLabel}} 
                      </div>  
                      <div class="informations-item">  
                        <span class="informations-item-title">{{$t('_contract_date')}} :</span> {{utils.formatDate(subscription.creationDate)}}<br>
                      </div>
                    </div>
                  </div>
              </div>
          </div>

          <div class="blockInformation block-m-height block-x1 frame-content-left margin-left-2 margin-top-20">
              <div class="frame-top-content"> 
                {{$t('_my_subscription')}}
                
              </div>
              <div class="informations">
                  <div class="informations-item">
                          <span class="informations-item-title">{{$t('_date')}} :</span> {{ utils.formatDate(this.$store.state.user.created)}}<br>
                  </div>
                  <div class="informations-item">
                    <div class="informations-item-left">{{$t('_if_u_want_terminate')}} </div>
                    <div class="informations-item-right">
                      <router-link :to="{ name: 'cancellation'}"><u>{{$t('_terminate')}}</u></router-link>
                    </div>
                  </div>
              </div>
          </div>
        </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')">
    </footerApp>

</div>
</template>

      



<script>
import {mapGetters} from "vuex";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";
import Utils from "../../utils/Util";
import controls from "../functionnals/controls";

export default {
    name: 'PersonalInformations',
    components:{
        headerApp,
        verticalMenu,
        footerApp,
        controls
    },

    props: {
    },

    data: function(){
        return {
          utils: new Utils()
        }
    },

    computed: {
        ...mapGetters([
          'getPagesByLocation',
          'getSubscriptionsWithoutConsents',
        ]),
    },

    filters: {
      defaultLabel: function (value) {
          if (!value || value=='') 
            return 'N/A';
          return value;
      }
    },
  }
</script>

