<template>
    <article>
            <link :href="appCss" rel="stylesheet">
            <div 
            class="smartcoach-profile-professional"
            data-internal=1
            :data-data-url="questionsUrl"
            :data-answer-url="urlSavingProfileAnswers" 
            :data-authentication-token="this.$store.state.accessToken"
            :data-lang="this.$store.state.lang.lang"
            :data-reference="sid" 
            :data-consumption-url-callBack="dataConsumptionUrlCallBack"
            :data-dashboard-url-callBack="dataDashboardUrlCallBack"
            data-svp="" 
            data-application="portailAutonome" 
            data-application-key="FGJHWXCVè'(-JFghjY890?V,fgn345634565476HJh" 
            >
            </div>
    </article>

</template>


<script>
    export default {
        
        name: 'ProfessionalProfileWidget',

        props: {
            questionsUrl: {type: String, required: false},
            urlWidgetProfile: {type: String, required: true},
            sid: {type: Number, required: true},
            urlSavingProfileAnswers: {type: String, required: true},
            dataConsumptionUrlCallBack: {type: String, required: false},
            dataDashboardUrlCallBack: {type: String, required: false},
        },

        data: function(){
            return {
                appCss: '',
                isLoadedApp: false,
                isLoadedChunk: false,
            }
        },

        mounted() {

            this.appCss = this.urlWidgetProfile + '/css/app.css';

            let appScript = this.urlWidgetProfile + '/js/app.js'

            
            this.$unloadScript(appScript)
            .then(() => {
                this.$loadScript(appScript).then(() => {
                    this.isLoadedApp = true;
                })
                .catch(() => {
                    this.isLoadedApp = false;
                });
            })
            .catch(() => {
                 this.$loadScript(appScript).then(() => {
                    this.isLoadedApp = true;
                })
                .catch(() => {
                    this.isLoadedApp = false;
                });
            });
        }
    }
</script>