<template>
  <div class='container-fluid auth-page'>
    <div class="row">
      <div class="col-md-11 offset-md-1  d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-5 col-md-7">
                    <div class="page-not-logged-in card rounded shadow shadow-sm">
                      <h3> {{ this.$i18n.t('not-found') }} </h3>
                      <router-link :to="home">Home</router-link>
                    </div>
                </div>
            </div>
            <!--/row-->
        </div>
    </div>
  </div>
</template>




<script>
  export default {
    
    name: 'PageNotFound',
    data: function () {
      return {
        home: '',
      }
    },

    mounted() {
      this.$store.dispatch('fetchUser');
      const user = this.$store.state.user;
      this.home = '/' + user.client.name + '/home';
    }


  }
</script>