<template>
  <div class='container-fluid auth-page reset-password-page'>
    <div class="row">
      <div class="col-md-11 offset-md-1  d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-5 col-md-7">
                    <div class="card rounded shadow shadow-sm">
                       <div class="card-header">
                            <img src="../../assets/onConnectLogo.png">
                        </div>
                        <div class="card-body">
                          <h4 class="mb-0">{{ this.$i18n.t('_forgotten_password_panel') }}</h4>
                          <br>
                          <p v-html="this.$i18n.t('_description_forgotten_password')"></p>

                          
                          <div v-if="!isSent">
                            <form class="form" @submit.prevent="handleSubmit">
                                <div class="form-group">
                                    <label>{{ this.$i18n.t('E-mail') }}</label>
                                    <input :placeholder="this.$i18n.t('enter-email')" type="email" class="form-control form-control-lg rounded-0" v-model="email" required="true">
                                </div>
                                <spinner></spinner>
                                <button class="btn btn-secondary btn-lg form-control">{{ this.$i18n.t('_validate') }}</button>
                            </form>
                          </div>

                          <div v-if="isSent">
                            <b-alert variant="success" show>
                                {{ this.$i18n.t('_forgotten_password_success') }}
                            </b-alert>
                          </div>

                          <div v-if="apiHasError">
                          <br>
                            <b-alert variant="danger" show>
                                {{apiErrorMessage}}
                            </b-alert>
                          </div>

                          <br>
                            <label>
                                <router-link to="login">
                                    <span class="custom-control-description small text-dark">{{ this.$i18n.t('login') }} ?</span>
                                </router-link>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import api from "../../utils/api";
import spinner from "../functionnals/spinner";
export default {

    name: 'ForgottenPassword',

    components:{
        spinner
    },

    data: function () {
        return {
            email: '',
            isSent: false,
            apiHasError: false
        }
    },

    methods: {
        
        handleSubmit () {
            const { email } = this;
            
            this.apiHasError = false;
            if (email) {
                api.forgottenPassword(email).then(() => {
                    this.apiHasError = false;
                    this.isSent = true;
                }).catch((status) => {
                    this.apiHasError = true;
                    if(status == 400){
                        this.apiErrorMessage = this.$i18n.t('_user_not_found');
                    }else if(status == 403){
                        this.apiErrorMessage = this.$i18n.t('_request_already_sent');
                    }else{
                        this.apiErrorMessage = this.$i18n.t('_server_error');
                    }
                    
                });
            }
            
        }
    }
}
</script>
