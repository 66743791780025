<template>
<div>

  <header-app></header-app>
  <vertical-menu
        :subscriptions="this.$store.state.user.subscriptions"
        :services="this.$store.state.user.services"
        :pages="getPagesByLocation('top')"
      >
      </vertical-menu>
    <div class="main-page my-consumption-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
      <div class="main-page-content">
        <controls></controls>

        <h1 v-if='page'> {{page.title}} </h1>
        <p v-if='page' v-html="page.content"> </p>
      </div>
    </div>

  <footerApp
    v-if="this.$store.state.user.cguAcceptation"
    :pages="getPagesByLocation('bottom')">
  </footerApp>

</div>
</template>


<script>
import {mapGetters} from "vuex";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";

export default {
      name: 'SinglePage',
      components:{
        headerApp,
        verticalMenu,
        footerApp,
      },

    props: {
        pageId: {required: true}
    },

    data: function(){
        return {
            page: null
        }
    },

    computed: {
        ...mapGetters([
          'getPagesByLocation',
          'getSubscriptionsWithoutConsents',
          'getPageById'
        ]),
    },

    mounted(){
        this.page = this.getPageById(this.pageId);
    }
  }
</script>

