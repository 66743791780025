import Configs from "../../configs";

const urls = {
    'login': Configs.value('VUE_APP_URL') + '/login_check',
    'validateTokenInitialisation': Configs.value('VUE_APP_URL') + '/validate/token',
    'resetPassword': Configs.value('VUE_APP_URL') + '/password/reset/confirm',
    'forgottenPassword': Configs.value('VUE_APP_URL') + '/password/reset',
    'loginByRefreshToken': Configs.value('VUE_APP_URL_BASE') + '/token/refresh',
    'subscription': Configs.value('VUE_APP_URL') + '/secure/subscription',
    'changePassword': Configs.value('VUE_APP_URL') + '/secure/password/change',
    'questions': Configs.value('VUE_APP_URL') + '/secure/question',
    'urlSavingProfileAnswers': Configs.value('VUE_APP_URL') + '/secure/response/subscription',
    'consumption': Configs.value('VUE_APP_URL') + '/secure/consumption/subscription',
    'userInfo': Configs.value('VUE_APP_URL') + '/secure/me',
    'terminate': Configs.value('VUE_APP_URL') + '/secure/terminate',
    'affectServiceToMe': Configs.value('VUE_APP_URL') + '/secure/user/affect-service-to-user',
    'acceptCgu': Configs.value('VUE_APP_URL') + '/secure/subscription/accept-cgu',
    'changePdsLabel': Configs.value('VUE_APP_URL') + '/secure/subscription/change-pds-label',
    'changePdsLabels': Configs.value('VUE_APP_URL') + '/secure/subscription/change-pds-labels',
    'subscriptionsJwtTokens': Configs.value('VUE_APP_URL') + '/secure/subscription/get-tokens',
    'JwtAuthentificationUrl': Configs.value('VUE_APP_JWT_AUTHENTIFICATION_URL') + "/login",
    'contactSendMessage': Configs.value('VUE_APP_URL') + '/secure/user/send-contact-message',
    'saveCookies': Configs.value('VUE_APP_URL') + '/secure/user/save-cookies'
};


export default {
    urls
}
