<template>
  <div>
    
    <ParticularProfileWidget 
      :questionsUrl="questionsUrl" 
      :urlWidgetProfile="urlWidgetProfile"
      :sid="sid" 
      :urlSavingProfileAnswers="urlSavingProfileAnswers"
      :dataConsumptionUrlCallBack="dataConsumptionUrlCallBack"
      :dataDashboardUrlCallBack="dataDashboardUrlCallBack"
      >
    </ParticularProfileWidget>


  </div>
</template>

<script>
import ParticularProfileWidget from "../../widgets/particularProfile";

  export default {
    name: 'ParticularProfile',
    components:{
        ParticularProfileWidget,
    },
    props: {
        questionsUrl: {type: String, required: true},
        urlWidgetProfile: {type: String, required: true},
        sid: {type: Number, required: true},
        urlSavingProfileAnswers: {type: String, required: true},
        dataConsumptionUrlCallBack: {type: String, required: false},
        dataDashboardUrlCallBack: {type: String, required: false},
    }
  }
</script>