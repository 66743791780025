<template>
<div>

    <template v-if="this.$store.state.user.service_client==null">
        <proPart 
          :services="getServices"
          :bgImg="bgImg"  
          @choose-me="affectServiceToMe">
        </proPart>
    </template>

    <template v-if="this.$store.state.user.service_client != null && !this.$store.state.user.cguAcceptation" >
        <cguPopup 
          :title="$i18n.t('_activate_service')" 
          :description="$i18n.t('_cgu_description')"   
          @show-cgu="showCgu"
          >
        </cguPopup>
    </template>

    <modal v-if="showcguModal" :bus="bus" :title="cguTitle" :content="cguContent"></modal>

</div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from "vuex";
import cguPopup from "./cguPopup";
import modal from "./modal";
import proPart from "./proPart";
import store from '../../store';
import api from "../../utils/api";

export default {
      name: 'Controls',
      components:{
        proPart,
        modal,
        cguPopup,
      },

      data: function(){
        return {
          showcguModal: false,
          bus: new Vue(),
          cguTitle: "",
          cguContent:"",
          bgImg:  `${require('@/assets/images/city.jpg')}`
        }
      },

      computed: {
        ...mapGetters([
          'getServices',
          'getPagesByLocation',
          'getSubscriptionsWithoutConsents',
          'getPageByType'
        ]),
      },

      methods: {
        //show cgu modal box
        showCgu() {
          const page = this.getPageByType('general_conditions');
          this.showcguModal = true;
          this.cguTitle = page.title;
          this.cguContent = page.content;
          this.bus.$emit('showModal');          
        },

        //assign a service to a subscription => store in database
        affectServiceToMe(result){
          api.affectServiceToMe(result).then((data) => {   
              store.dispatch('updateUser', data);
          }).catch((err) => {
              console.log(err);
          }).finally(() =>{
            store.dispatch('setLoading', false);
          });
        }
      },

      
  }
</script>