var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(
      this.$store.state.sessionExpired===true &&
      this.$store.state.refreshTokenExpired===true && 
      _vm.$route.path != '/login' && 
      _vm.$route.path != '/reset'  && 
      _vm.$route.path != '/forgotten-password'  && 
      _vm.$route.path != '/')?_c('loginPopup'):_vm._e(),_c('router-view',{key:_vm.$route.fullPath}),_c('footer',[(_vm.$store.state.accessToken && _vm.$store.state.user)?_c('footerCookies'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }