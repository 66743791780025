<template>
<div>
  <header-app></header-app>
    <vertical-menu 
      :subscriptions="this.$store.state.user.subscriptions" 
      :services="this.$store.state.user.services" 
      :pages="getPagesByLocation('top')"   
      >
    </vertical-menu>

    <div class="main-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
        
        <div class="main-page-content">
          <controls></controls>
          
          <div class="header-page">
              <h1 class="floatLeft bold upper">{{this.$i18n.t('_my_meter_edit_desc')}} </h1>
          </div> 
          
          <div class="blockInformation block-m-height block-x1 frame-content-left-auto-height">
            <div v-for="(subscription, index) in this.$store.state.user.subscriptions" :key="index">
              <div class="form-group mb-4">
                  <label for="staticEmail"><b>{{subscription.pds}}</b> - {{utils.formatDate(subscription.creationDate)}}</label>
                   <input type="text" class="form-control form-control-lg" v-model="formData['subscription-'+subscription.id]">
              </div>
            </div>
            <div class="col-md-6 col-6 pl-0">
                <div class="btn btn-lg rounded btn-suez-blue border-radius m-2" @click="modificate()">{{ $t('_validate') }}</div>
                <router-link :to="{ name: 'personalInformations'}">
                    <div class="btn btn-lg rounded btn-suez-dark-light-blue">{{ $t('_return') }}</div>
                </router-link>
              </div>
              <div v-if="isSuccess" class="col-md-12" style="display: initial;">
                <b-alert variant="success" show>
                    {{ this.$i18n.t('_edit_success') }}
                </b-alert>
              </div>
                <spinner></spinner>
          </div>
      
        </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')">
    </footerApp>

</div>
</template>



<script>
import {mapGetters} from "vuex";
import api from "../../utils/api";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";
import spinner from "../functionnals/spinner";
import Utils from "../../utils/Util";
import controls from "../functionnals/controls";

export default {
    name: 'Meter',
    components:{
        headerApp,
        verticalMenu,
        footerApp,
        spinner,
        controls
    },

    props: {},

    data: function(){
        return {
          isSuccess: null,
          formData: {},
          utils: new Utils()
        }
    },

    computed: {
        ...mapGetters([
          'getPagesByLocation',
          'getSubscriptionById',
          'getSubscriptionsWithoutConsents',
        ]),
    },

    mounted(){
        for (var j = 0; j < this.$store.state.user.subscriptions.length; j++) {
            this.formData['subscription-' + this.$store.state.user.subscriptions[j].id] = this.$store.state.user.subscriptions[j].pdsname;
        }
     },

    methods: {
      modificate: function(){

        api.changePdsLabels(this.formData).then((data) => {
             this.$store.dispatch('updateUser', data);
             this.isSuccess = true;
            setTimeout(() => {  this.$router.push({ name: 'personalInformations' }); }, 2000);
          }).catch((err) => {
            this.isSuccess = false;
            console.log(err); 
          });

      }
    }
  }
</script>

