import apiEndpoints from '../utils/apiEndpoints';
import store from '../store';
import ApiRequest from '../utils/ApiRequest';
import axios from 'axios';
import Configs from "../../configs";

/*************************** Jwt Authentification ************* */

const jwtLogin = async function(data, internalParams, clientApiKey) {

    const headers = {
        'apikey': (clientApiKey) ? clientApiKey : Configs.value('VUE_APP_JWT_AUTHENTIFICATION_API_KEY'),
        'content-type': 'application/json'
    };

    var form = data;

    return await new Promise((resolve, reject) => {
        axios.post(
            apiEndpoints.urls.JwtAuthentificationUrl,
            form, {
                headers: headers
            }
        ).then((response) => {
            response.data.pds = data.servicePoint;
            response.data.creationDate = data.date;
            response.data.name = internalParams.name;
            response.data.color = internalParams.color;

            resolve(response.data);
        }).catch((error) => {
            reject(error.response.status);
        });
    });

}

/************************** GET APIS ************************** */

const getWidget = function(subscription) {

    var url = apiEndpoints.urls.subscription + "/" + subscription.id + "/widget";
    store.dispatch('setLoading', true);
    store.dispatch('saveError', '');
    const headers = { Authorization: 'Bearer ' + store.state.accessToken };

    var apiRequest = new ApiRequest();
    return (apiRequest.doGet(url, headers));

};


const getConsumption = function(subscriptionId, month) {

    var url = apiEndpoints.urls.consumption + "/" + subscriptionId + "?langCode=" + store.state.lang.lang + "&month=" + month;

    const headers = { Authorization: 'Bearer ' + store.state.accessToken };

    var apiRequest = new ApiRequest();
    return (apiRequest.doGet(url, headers));

};

const terminate = function() {

    var url = apiEndpoints.urls.terminate;
    store.dispatch('setLoading', true);

    const headers = { Authorization: 'Bearer ' + store.state.accessToken };
    var apiRequest = new ApiRequest();
    return (apiRequest.doGet(url, headers));

};

const getSubscriptionsTokens = function() {

    var url = apiEndpoints.urls.subscriptionsJwtTokens;
    store.dispatch('setLoading', true);

    const headers = { Authorization: 'Bearer ' + store.state.accessToken };
    var apiRequest = new ApiRequest();
    return (apiRequest.doGet(url, headers));

};

/************************** POST APIS ********************************** */

const verifyTokenInitialisation = function(token) {

    var url = apiEndpoints.urls.validateTokenInitialisation + "/" + token;
    store.dispatch('setLoading', true);
    store.dispatch('saveError', '');

    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url));

};

const dologin = function(email, password) {

    var url = apiEndpoints.urls.login;
    store.dispatch('setLoading', true);

    const FormData = require('form-data');
    const form = new FormData();
    form.append('username', email);
    form.append('password', password);

    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, form));
};

const doLoginByRefreshToken = function() {

    var url = apiEndpoints.urls.loginByRefreshToken;
    store.dispatch('setLoading', true);

    const FormData = require('form-data');
    const form = new FormData();
    form.append('refresh_token', store.state.refreshToken);

    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, form));
};


const resetPassword = function(token, password, passwordValidation) {

    var url = apiEndpoints.urls.resetPassword;
    store.dispatch('setLoading', true);
    store.dispatch('saveError', '');

    const FormData = require('form-data');
    const form = new FormData();
    form.append('token', token);
    form.append('plainPassword[first]', password);
    form.append('plainPassword[second]', passwordValidation);

    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, form));

};

const forgottenPassword = function(email) {

    var url = apiEndpoints.urls.forgottenPassword;
    store.dispatch('setLoading', true);

    const FormData = require('form-data');
    const form = new FormData();
    form.append('email', email);


    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, form));
};


const acceptCgu = function() {

    var url = apiEndpoints.urls.acceptCgu;

    const FormData = require('form-data');
    const form = new FormData();
    form.append('langCode', store.state.lang.lang);
    const headers = { Authorization: 'Bearer ' + store.state.accessToken };
    store.dispatch('setLoading', true);
    var apiRequest = new ApiRequest();

    return (apiRequest.doPost(url, form, headers));
};



const affectServiceToMe = function(data) {

    var service = data.service;
    var url = apiEndpoints.urls.affectServiceToMe;
    store.dispatch('setLoading', true);
    const FormData = require('form-data');
    const form = new FormData();
    form.append('service', service);
    form.append('langCode', store.state.lang.lang);
    const headers = { Authorization: 'Bearer ' + store.state.accessToken };

    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, form, headers));
};


const getUser = function() {
    var lang = store.state.lang.lang;
    var url = apiEndpoints.urls.userInfo;
    store.dispatch('setLoading', true);
    const FormData = require('form-data');
    const form = new FormData();
    form.append('langCode', lang);

    const headers = { Authorization: 'Bearer ' + store.state.accessToken };

    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, form, headers));

};

const changePassword = function(currentPassword, newPassword, verifyNewPassword) {

    var url = apiEndpoints.urls.changePassword;
    store.dispatch('setLoading', true);

    const FormData = require('form-data');
    const form = new FormData();
    form.append('current_password', currentPassword);
    form.append('plainPassword[first]', newPassword);
    form.append('plainPassword[second]', verifyNewPassword);

    const headers = { Authorization: 'Bearer ' + store.state.accessToken };

    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, form, headers));
};

const changePdsLabel = function(subscriptionId, label) {

    var url = apiEndpoints.urls.changePdsLabel + '/' + subscriptionId;

    store.dispatch('setLoading', true);
    const FormData = require('form-data');
    const form = new FormData();
    form.append('label', label);
    form.append('langCode', store.state.lang.lang);

    const headers = { Authorization: 'Bearer ' + store.state.accessToken };

    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, form, headers));
};

const changePdsLabels = function(formData) {

    var url = apiEndpoints.urls.changePdsLabels;
    store.dispatch('setLoading', true);
    const data = {
        pdsList: formData,
        langCode: store.state.lang.lang
    };
    const headers = { Authorization: 'Bearer ' + store.state.accessToken };
    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, data, headers));
};


const contactSendMessage = function(formData) {

    var url = apiEndpoints.urls.contactSendMessage;
    store.dispatch('setLoading', true);
    const data = formData;
    const headers = { Authorization: 'Bearer ' + store.state.accessToken };
    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, data, headers));
};


const saveCookies = function(data) {

    const dataResult = {
        cookies: data,
        langCode: store.state.lang.lang
    };

    var url = apiEndpoints.urls.saveCookies;
    store.dispatch('setLoading', true);
    const headers = { Authorization: 'Bearer ' + store.state.accessToken };
    var apiRequest = new ApiRequest();
    return (apiRequest.doPost(url, dataResult, headers));
};




const api = {
    dologin,
    affectServiceToMe,
    acceptCgu,
    getWidget,
    resetPassword,
    verifyTokenInitialisation,
    forgottenPassword,
    changePassword,
    getConsumption,
    getUser,
    terminate,
    changePdsLabel,
    changePdsLabels,
    getSubscriptionsTokens,
    doLoginByRefreshToken,
    jwtLogin,
    contactSendMessage,
    saveCookies
};


export default api;