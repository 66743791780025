<template>
  <div>
    <header-app></header-app>
    <vertical-menu
      :subscriptions="this.$store.state.user.subscriptions"
      :services="this.$store.state.user.services"
      :pages="getPagesByLocation('top')"
    >
    </vertical-menu>

    <div class="main-page my-consumption-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
      
      <div class="main-page-content">
        <controls></controls>

        <div class="header-page">
          <h1 class="floatLeft bold upper">{{this.$i18n.t('_my_consumption')}}</h1>
        </div>
        <template v-for="(otherSubscription, index) in this.$store.state.user.subscriptions">
            <ProfileWidgetLink
            v-if="otherSubscription.id==subscriptionId"
            type="profile"
            :subscription="otherSubscription" 
            :key="'profile' + otherSubscription.id"
          ></ProfileWidgetLink>
          <ProfileWidgetLink
            v-else
            type="subscription"
            :subscription="otherSubscription" 
            :key="'subscription' + otherSubscription.id"
          ></ProfileWidgetLink>
          <template v-if='subscription && otherSubscription.id == subscription.id'>
            <div class="vertical-drop-down-container" :key="index" :class="[serviceName =='SmartCoachPro' ? 'custom-bg' : '']">
              <div class="white-container">
                <div class="header pb-4 clearfix" v-if="subscription">
                    <div class="float-left pr-3" v-if="subscription && serviceName == 'SmartCoachPro'">
                      <span class="title">{{ $t('_counter') }} ({{subscription.pds}}) :</span>
                    </div>
                    <div class="col-md-8 offset-md-2 pt4">
                      <span class="subtitle-x15" v-if="serviceName == 'SmartCoachPro'">{{ $t('_consumption_composition_pro') }}</span>
                    </div>
                </div>
              </div>
              <div class="pro-part-container">
                  <ParticularProfile
                    v-if="subscription && serviceName == 'SmartCoach'"
                    :questionsUrl="questionsUrl"
                    :urlWidgetProfile="urlWidgetProfile"
                    :sid="subscription.id"
                    :urlSavingProfileAnswers="urlSavingProfileAnswers"
                    :dataConsumptionUrlCallBack="dataConsumptionUrlCallBack" 
                    :dataDashboardUrlCallBack="dataDashboardUrlCallBack" 
                    :key="'SmartCoach' + subscription.id"
                  ></ParticularProfile>

                  <ProfessionalProfile v-if="subscription && serviceName == 'SmartCoachPro'"
                    :questionsUrl="questionsUrl"
                    :urlWidgetProfile="urlWidgetProfile"
                    :sid="subscription.id"
                    :urlSavingProfileAnswers="urlSavingProfileAnswers"
                    :dataConsumptionUrlCallBack="dataConsumptionUrlCallBack"
                    :dataDashboardUrlCallBack="dataDashboardUrlCallBack"
                    :key="'SmartCoachPro' + subscription.id"
                  ></ProfessionalProfile>
              </div>  
            </div>
          </template>
        </template>        
      </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')"
    >
    </footerApp>
  </div>
</template>
      
<script>
import { mapGetters } from "vuex";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";
import ProfileWidgetLink from "../functionnals/profileWidgetLink";
import ParticularProfile from "../functionnals/profiles/particularProfile";
import ProfessionalProfile from "../functionnals/profiles/professionalProfile";
import apiEndpoints from "../../utils/apiEndpoints";
import controls from "../functionnals/controls";

export default {
  name: "Profile",
  components: {
    headerApp,
    verticalMenu,
    footerApp,
    ParticularProfile,
    ProfileWidgetLink,
    ProfessionalProfile,
    controls,
  },

  props: {
    client: { type: String, required: true },
    subscriptionId: { required: true },
  },

  data: function () {
    return {
      showQuestions: false,
      answers: [],
      subscription: null,
      serviceName: this.$store.state.user.service_client.name,
      urlWidgetProfile: this.$store.state.user.service_client.urlWidgetProfile
    };
  },

  computed: {
    ...mapGetters([
      "getPagesByLocation",
      "getSubscriptionById",
      "getSubscriptionsWithoutConsents",
    ]),

    computedQuestions: function () {
      var result = Object.keys(this.$store.state.user.services)
        .map((key) => this.$store.state.user.services[key])
        .filter(
          (service) =>
            service.name == this.$store.state.user.service_client.name
        );
      return result[0].questions;
    },
    questionsUrl: function () {
      return (
        apiEndpoints.urls.questions +
        "/" +
        this.subscription.id +
        "?langCode=" +
        this.$store.state.lang.lang
      );
    },

    urlSavingProfileAnswers: function () {
      return apiEndpoints.urls.urlSavingProfileAnswers;
    },
    dataConsumptionUrlCallBack: function () {
      const consumptionCallback = this.$router.resolve({ name: 'subscription', params: { client: this.$store.state.user.client.name, subscriptionId: this.subscriptionId }});
      return consumptionCallback.href;
    },
    dataDashboardUrlCallBack: function () {
      const dashboardCallback = this.$router.resolve({ name: 'home', params: { client: this.$store.state.user.client.name }});
      return dashboardCallback.href;
    },
  },

  beforeUpdate() {
    this.initialize();
    this.subscription = this.getSubscriptionById(this.subscriptionId);
  },

  methods: {
    initialize: function () {
      this.showQuestions = false;
      this.subscription = null;
    },
  },
};
</script>

