<template>
<div>

  <header-app></header-app>
  <vertical-menu 
    :subscriptions="this.$store.state.user.subscriptions" 
    :services="this.$store.state.user.services" 
    :pages="getPagesByLocation('top')"   
    >
  </vertical-menu>

    <div class="main-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
        
        <div class="main-page-content">
            <controls></controls>
            
            <alarmWidget v-if="pdsList.length == this.$store.state.user.subscriptions.length" :pdsList="pdsList"></alarmWidget>

      </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')">
    </footerApp>

</div>
</template>
    


<script>
import {mapGetters} from "vuex";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";
import controls from "../functionnals/controls";
import alarmWidget from "../widgets/alarm";
import api from "../../utils/api";
import Utils from "../../utils/Util";

export default {
    name: 'Alerts',
    components:{
        headerApp,
        verticalMenu,
        footerApp,
        controls,
        alarmWidget
    },

    data: function(){
          return {
              pdsList: [],
              utils: new Utils()
          }
      },

    computed: {
        ...mapGetters([
          'getPagesByLocation',
        ]),
    },

    mounted() {

      this.getPdsListWithTokens();

    },

    methods: {

        getPdsListWithTokens: function() {
          
          var pdsTable = this.$store.state.user.subscriptions;
          var clientApiKey = this.$store.state.user.client.consumer_api_key;
         

          for (let i = 0; i < pdsTable.length; i++) {

            var creationDate = this.utils.formatDateForLogin(pdsTable[i].creationDate);

            var loginData = { servicePoint: pdsTable[i].pds, date: creationDate };

            var internalParams = { name: pdsTable[i].pdsname, color: pdsTable[i].color };

            api.jwtLogin(loginData, internalParams, clientApiKey).then((data) => {

              this.pdsList.push(
                {
                  pds: data.pds,
                  creationDate: data.creationDate,
                  token: data.token,
                  name: data.name,
                  color: data.color
                }
              );

            }).catch((status) => {
              console.log(status);           
            });
            
          }
        },
    }
  }
</script>

