<template>
        <div class='activation-popup'>
            <br>
            <h2>{{this.$i18n.t('_informations')}}</h2>
            <br>
            <p v-html="this.$i18n.t('_your_service_activation_info', {link: linkToProfile})"></p>
        </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {

    props:{
        subscription: {required: true}
    },

    data: function(){
        return {
            client: ""
        }
    },

    mounted() {
      this.$store.dispatch('fetchUser');
      const user = this.$store.state.user;
      this.client = user.client.name;
    },

    computed: {

        ...mapGetters([
          'getUserTypeProfile'
        ]),

        linkToProfile: function(){
            return '/' + this.client + '/subscriptions/profile/' + this.subscription.id;
        }
    }

}
</script>