<template>
<div>
    <header-app></header-app>
    <vertical-menu 
      :subscriptions="this.$store.state.user.subscriptions" 
      :services="this.$store.state.user.services" 
      :pages="getPagesByLocation('top')"   
      >
    </vertical-menu>
    <div class="main-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
        <div class="main-page-content">
          <controls></controls>
          <dashboard v-if="this.$store.state.user.service_client != null && this.$store.state.user.cguAcceptation"></dashboard>
        </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')">
    </footerApp>

</div>
</template>


<script>
import {mapGetters} from "vuex";
import verticalMenu from "../functionnals/menu";
import headerApp from "../functionnals/header";
import footerApp from "../functionnals/footer";
import dashboard from "../functionnals/dashboard";
import controls from "../functionnals/controls";

export default {

      name: 'Home',
      components:{
        verticalMenu,
        headerApp,
        footerApp,
        dashboard,
        controls
      },

      computed: {
        ...mapGetters([
          'getPagesByLocation',
          'getSubscriptionsWithoutConsents'
        ]),
      },
  }
</script>

