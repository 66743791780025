import 'es6-promise';
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import router from './utils/routes';
import i18n from './utils/i18n';
import store from './store';
import LoadScript from 'vue-plugin-load-script';
import App from './App.vue';
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import highchartsMore from 'highcharts/highcharts-more';
import solidgauge from "highcharts/modules/solid-gauge";
import VueMatomo from 'vue-matomo';
import Configs from "../configs";

Vue.config.productionTip = false;
// Bootstrap
Vue.use(BootstrapVue);

Vue.use(LoadScript);

Vue.use(HighchartsVue, { Highcharts });
highchartsMore(Highcharts);
solidgauge(Highcharts);

Vue.use(require('vue-moment'));

Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: Configs.value('VUE_APP_MATOMO_HOST'),
    siteId: Configs.value('VUE_APP_MATOMO_SITE_ID'),

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    trackerFileName: 'matomo',

    // Enables automatically registering pageviews on the router
    router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: true,

    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: false,

    // Require consent before creating matomo session cookie
    // Default: false
    requireCookieConsent: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: false,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 15,

    // Whether or not to log debug information
    // Default: false
    debug: false,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    userId: undefined,

    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
    // Default: undefined, example '*.example.com'
    cookieDomain: undefined,

    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
    // Default: undefined, example: '*.example.com'
    domains: undefined,

    preInitActions: [],

    trackSiteSearch: true
});



// Check that service workers are supported
if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js');
    });
}

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')