<template>

    <article>
            <link :href="appCss" rel="stylesheet">
            <div 
            ref="widgetDashboardPro"
            class="portailAutonome-dashboard-pro"
             :data-lang="this.$store.state.lang.lang" 
             :data-length="pdsList.length" 
             :data-profile-url="profileUrl" 
             data-application="portailAutonome" 
             data-application-key="QDFQDF567576567DFGH456'-DFG456DFG456DFG-" 
            >
            </div>
    </article>

</template>


<script>
import Configs from "../../../configs";

    export default {
        
        name: 'ProfessionalDashboardWidget',

        props: {
            pdsList: {type: Array, required: false}
        },

        data: function(){
            return {
                appCss: '',
                isLoadedApp: false,
                isLoadedChunk: false,
                profileUrl: '',
            }
        },

        mounted() {

            var widget = this.$refs.widgetDashboardPro;
            this.pdsList.map(function(value, key) {
                var counter = parseInt(key) + 1;
                widget.setAttribute('data-svp' + counter, value.pds);
                widget.setAttribute('data-name' + counter, value.name);
                widget.setAttribute('data-token' + counter, value.token);
                widget.setAttribute('data-reference' + counter, value.id);

                if(value.area || value.employeeNb || value.housingNb){
                    widget.setAttribute('data-area' + counter, parseInt(value.area));
                    widget.setAttribute('data-employeeNb' + counter, parseInt(value.employeeNb));
                    widget.setAttribute('data-housingNb' + counter, parseInt(value.housingNb));
                }
                

            }, widget);

            this.profileUrl = location.origin + '/' + this.$store.state.user.client.name + '/subscriptions/profile/:reference';

            this.appCss = Configs.value('VUE_APP_DASHBOARD_PRO_WIDGET_URL') + '/css/app.css';

            let appScript = Configs.value('VUE_APP_DASHBOARD_PRO_WIDGET_URL') + '/js/app.js';
            
            this.$unloadScript(appScript)
                .then(() => {
                    this.$loadScript(appScript).then(() => {
                        this.isLoadedApp = true;
                    })
                    .catch(() => {
                        this.isLoadedApp = false;
                    });
                })
                .catch(() => {
                    this.$loadScript(appScript).then(() => {
                        this.isLoadedApp = true;
                    })
                    .catch(() => {
                        this.isLoadedApp = false;
                    });
                });
        },
    }
</script>
