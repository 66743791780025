<template>
  <div>
    <div class="header-page">
        <h1 class="floatLeft bold upper">{{ this.$i18n.t('_dashboard') }} : </h1>
        <div v-if="subscription.activated" class="form-group floatLeft margin-left-10px">
            <select class="form-control borderNone" v-model="selected">
                <option v-for="(month, index) in this.periodListMonth" :key="index" v-bind:value="month.value"> 
                    {{month.text}}
                </option>
            </select>
        </div>
    </div>
    <particularDashboardInformations v-if="subscription && selected" :subscription="subscription" :month="selected"></particularDashboardInformations>
  </div>
</template>

<script>
    import particularDashboardInformations from './particularDashboardInformations.vue'
    export default {
        name: 'ParticularDashboard',
        components:{
            particularDashboardInformations
        },
        data () {
            return {
                selected: null
            }
        },
        props: {
            subscription: {type: Object, required: true},
            ident: {type: Number}
        },

        mounted(){
            var myDate = new Date();
            myDate.setDate(1);
            myDate.setMonth(myDate.getMonth() - 1);
            this.selected = this.getOptionValue(myDate);
        },

        computed:{
            
            periodListMonth() {
                const monthYears = [];
                const currentDate = new Date();
                //currentDate.setDate(1);
                //currentDate.setMonth(currentDate.getMonth() - 1);
                
                const dateHistoryMax = new Date(currentDate);
                dateHistoryMax.setMonth(dateHistoryMax.getMonth() - 24);
                
                for (const date = currentDate; currentDate > dateHistoryMax; currentDate.setMonth(currentDate.getMonth() - 1)) {
                    monthYears.push({
                        value: this.getOptionValue(date),
                        text: `${this.$i18n.t('_month')[date.getMonth() + 1]} ${date.getFullYear()}`,
                    });
                }
                return monthYears;
            },
        },

        methods: {
            getOptionValue(date) {
                return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`;
            },
        },
    }
</script>