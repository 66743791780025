<template>
    <div>
        <img v-if="this.$store.state.user.client.backgroundImage==null" :src="bgImg" class="bg"> 

        <img v-if="this.$store.state.user.client.backgroundImage"  v-bind:src="this.$store.state.user.client.backgroundImage" class="bg" />
        
        <div class='propart-popup col-md-9 col-lg-8 col-xl-7 col-11 center'>
            
            <spinner></spinner>
            <div class="propart-popup-description">
                <p>{{this.$i18n.t('_who_are_you_description')}}  </p>
            </div>

            <div class="propart-popup-form">
                
                <div class="propart-popup-form-title" v-html="this.$i18n.t('_are_you')"></div>
                
                <div class="propart-popup-form-choices">
                    <div v-for="(service) in services" :key="service.id" @click="select(service.id)" class="btn-pro-part rounded" :class="(serviceId==service.id ? 'active-pro-part' : '')">
                        {{ service.areYou}}
                    </div>
                </div>
                <br>
                
                <div class="propart-popup-form-choices">
                    <div @click="choose()" class="btn-pro-part btn-pro-part-validate rounded float-right" :class="(!serviceId ? 'disabled' : 'enabled')">
                        {{this.$i18n.t('_validate')}}
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>


<script>

    import spinner from "./spinner";
    export default {

        components:{
            spinner
        },
        
        props: {
            services: {type: Array, required: true},
            bgImg: {required: true},
        },

        data: function(){
            return { 
                serviceId: null
            }
        },

        methods:{

            select: function(serviceId){
                this.serviceId = serviceId;
            },

            choose: function(){
                
                if(this.serviceId != null){
                    this.$emit('choose-me', {service: this.serviceId});
                }
                
            }
        },
    }
</script>