import store from "../index";


const getters = {
    getSubscriptionsWithoutService(){
        const subscriptions = store.state.user.subscriptions;
        var result = Object.keys(subscriptions)
            .map(key => subscriptions[key])
            .filter(subscription =>  subscription.service == null) 

        return (result);
    },

    getSubscriptionsWithService(){
        const subscriptions = store.state.user.subscriptions;
        var result = Object.keys(subscriptions)
            .map(key => subscriptions[key])
            .filter(subscription =>  subscription.service != null) 

        return (result);
    },

    getSubscriptionById: () => (id) => {
        const subscriptions = store.state.user.subscriptions;
        var result = Object.keys(subscriptions)
            .map(key => subscriptions[key])
            .filter(subscription =>  subscription.id == id) 

        return (result[0]);
    },

    getServices: () => {
        const services = store.state.user.services;
        var result = Object.keys(services)
            .map(key => services[key])
            .filter(service =>  service.type == null) 

        return (result);
    },

    getExternalWidgets: () => {
        const services = store.state.user.services;
        var result = Object.keys(services)
            .map(key => services[key])
            .filter(service =>  service.type == 'external') 

        return (result);
    },

    getSubscriptionsWithoutConsents(){
        const subscriptions = store.state.user.subscriptions;
        var result = Object.keys(subscriptions)
            .map(key => subscriptions[key])
            .filter(subscription =>  subscription.consent == null) 

        return (result);
    },

    getSubscriptionsCount: () => store.state.user.subscriptions.length,
};


const actions = {
    
};

export default {
    getters,
    actions
  }