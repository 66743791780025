import store from "../index";

const getters = {
    getPagesByLocation: () => (location) => {

        var pages = store.state.user.pages;

        var result = Object.keys(pages)
            .map(key => pages[key])
            .filter(page =>  page.locations.includes(location));
            
        return result;
      },

      getPageById: () => (pageId) => {

        var pages = store.state.user.pages;

        var result = Object.keys(pages)
            .map(key => pages[key])
            .filter(page =>  page.id == pageId);
            
        return result[0];
      },

      getPageByType: () => (pageType) => {

        var pages = store.state.user.pages;

        var result = Object.keys(pages)
            .map(key => pages[key])
            .filter(page =>  page.type == pageType);
            
        return result[0];
      }
};

export default {
    getters
  }