<template>
        <div id="swatch_wpml_language_selector-4">
            <ul class="inline">
                <li v-for="lang in languages" :key="lang" :value="lang">
                    <a href="#" @click="switchLang(lang)">
                        <img v-bind:src="require('../../assets/images/flags/'+lang+'.png')" >
                    </a>
                </li>
            </ul>
        </div>
</template>
<script>
export default {
    data: function () {
        return {
          languages: this.$store.state.user.languages,
          selected: this.$store.state.lang.lang
        }
    },
     methods: {
        switchLang: function(lang) {
            this.$store.dispatch('getUserByLang', lang);
            this.$store.dispatch('updateLang', lang);
            this.$router.go();
        }
    },
}
</script>