<template>
<div>
  <header-app></header-app>
  <vertical-menu 
    :subscriptions="this.$store.state.user.subscriptions" 
    :services="this.$store.state.user.services" 
    :pages="getPagesByLocation('top')"   
    >
  </vertical-menu>

  <div class="main-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
      <div class="main-page-content">
        <controls></controls>
        
        <div class="header-page">
            <h1 class="floatLeft bold upper">{{this.$i18n.t('_title_change_password')}}</h1>
        </div> 

        <div class="blockInformation block-m-height block-x1 block-m-height frame-content-left-auto-height">
          <spinner></spinner>
          <form class="form" @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="currentPassword"><b>{{ this.$i18n.t('_current_password') }}</b></label>
                <input :placeholder="this.$i18n.t('_enter_current_password')" type="password" class="form-control form-control-lg" v-model="currentPassword" name="currentPassword" required="true">
            </div>
            <div class="form-group">
                <label><b>{{ this.$i18n.t('_new_password') }}</b></label>
                <input :placeholder="this.$i18n.t('_enter_new_password')" type="password" class="form-control form-control-lg" v-model="newPassword" name="newPassword" required="true">
            </div>
            <div class="form-group">
                <label><b>{{ this.$i18n.t('_verify_new_password') }}</b></label>
                <input :placeholder="this.$i18n.t('_enter_verify_new_password')" type="password" class="form-control form-control-lg" v-model="verifyNewPassword" name="verifyNewPassword" required="true">
            </div>
            <div class="form-group" v-if="!match">
                <b-alert variant="danger" show>
                    {{ this.$i18n.t('_passwords_not_match') }}
                </b-alert>
            </div>
            <div v-if="apiHasError">
              <b-alert variant="danger" show v-html="apiErrorMessage"></b-alert>
            </div>
            <div v-if="isSent">
              <b-alert variant="success" show>
                  {{ this.$i18n.t('_new_password_set_successfully') }}
              </b-alert>
            </div>
            <button class="btn btn-suez-blue btn-lg form-control btn-width-auto">{{ this.$i18n.t('_change_password') }}</button>
          </form>
        </div>

        <div class="blockInformation block-m-height block-x1 frame-content-left-auto-height">
          <div class="informations ">
            <p><b>{{ this.$i18n.t('_change_password_note_1') }}</b></p>
            <p><i>{{ this.$i18n.t('_change_password_note_2') }}</i></p>
          </div>
        </div>

      </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')">
    </footerApp>

</div>
</template>
    
    



<script>
import {mapGetters} from "vuex";
import api from "../../utils/api";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";
import spinner from "../functionnals/spinner";
import controls from "../functionnals/controls";

export default {
    name: 'ChangePassword',
    components:{
        headerApp,
        verticalMenu,
        footerApp,
        spinner,
        controls
    },

    data: function(){
        return {
          match: true,
          apiHasError: false,
          apiErrorMessage: '',
          currentPassword: '',
          newPassword: '',
          verifyNewPassword: '',
          isSent: false

        }
    },

    computed: {
        ...mapGetters([
          'getPagesByLocation',
          'getSubscriptionsWithoutConsents'
        ]),
        
    },
    
    methods: {
      handleSubmit () {
            const { currentPassword, newPassword, verifyNewPassword} = this;
            this.match = true;
            this.apiHasError = false;
            this.isSent = false;

            if (currentPassword && newPassword && verifyNewPassword) {
              if(newPassword != verifyNewPassword){
                this.match = false;
              }else{
                api.changePassword(currentPassword, newPassword, verifyNewPassword).then(() => {
                    this.apiHasError = false;
                    this.isSent = true;
                }).catch((status) => {
                    this.apiHasError = true;
                    if(status == 404){
                        this.apiErrorMessage = this.$i18n.t('_password_do_not_match_user');
                    }else if(status == 400){
                        this.apiErrorMessage = this.$i18n.t('_check_passwords');
                    }else{
                        this.apiErrorMessage = this.$i18n.t('_server_error');
                    }
                    
                });
              }
            }            
        }
    },
  }
</script>

