import Vue from 'vue'
import VueI18n from 'vue-i18n';
import messages from './lang/';
import Utils from '../Util';

Vue.use(VueI18n);

var utils = new Utils();

var lang = utils.getLanguage(navigator.language.split('-')[0]);

const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: lang,
    messages
  });

export default i18n;