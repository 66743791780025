<template>
<div>
  <header-app></header-app>
    <vertical-menu 
      :subscriptions="this.$store.state.user.subscriptions" 
      :services="this.$store.state.user.services" 
      :pages="getPagesByLocation('top')"   
      >
    </vertical-menu>

    <div class="main-page my-consumption-page" :class="[!this.$store.state.menuOpen ? 'main-page-expanded' : '']">
        
        <div class="main-page-content">
          <controls></controls>

          <div class="header-page">
              <h1 class="floatLeft bold upper">{{this.$i18n.t('_my_consumption')}}</h1>
          </div> 

          <template v-for="(otherSubscription, index) in this.$store.state.user.subscriptions">
                <ProfileWidgetLink 
                  :key="index"
                  type="subscription" 
                  :subscription="otherSubscription" 
                  ></ProfileWidgetLink>
          </template>

        </div>
    </div>

    <footerApp
      v-if="this.$store.state.user.cguAcceptation"
      :pages="getPagesByLocation('bottom')">
    </footerApp>

</div>
</template>

<script>
import {mapGetters} from "vuex";
import headerApp from "../functionnals/header";
import verticalMenu from "../functionnals/menu";
import footerApp from "../functionnals/footer";
import ProfileWidgetLink from "../functionnals/profileWidgetLink";
import controls from "../functionnals/controls";

export default {
    name: 'Subscriptions',
    components:{
        headerApp,
        verticalMenu,
        footerApp,
        ProfileWidgetLink,
        controls
    },

    data: function(){
        return {
        }
    },

    computed: {
        ...mapGetters([
          'getPagesByLocation',
          'getSubscriptionById',
          'getSubscriptionsWithoutConsents'
        ]),
        
    },

  }
</script>

