<template>
  <div>
      <div  v-for="(pds, index) in pdsList" class="" :key="index"> 
        <div v-if="pds.token == 'invalid' ">
          <accountNotFoundModal :pds="pds.pds"></accountNotFoundModal >
        </div>
      </div>    
    <ProfessionalDashboardWidget v-if="pdsList" :pdsList="pdsList"></ProfessionalDashboardWidget>
    <div v-else><spinner></spinner></div>
  </div>
</template>

<script>
  
  import ProfessionalDashboardWidget from "../../widgets/dashboardPro";
  import api from "../../../utils/api";
  import store from "../../../store/";
  import spinner from "../spinner";
  import accountNotFoundModal from "../accountNotFoundModal";

  export default {
      name: 'ProDashboard',
      components:{
        ProfessionalDashboardWidget,
        spinner,
        accountNotFoundModal
      },

      data () {
        return {
          pdsList: null
        }
      },

      mounted() {
        
        //get tokens for widget dashboard

        api.getSubscriptionsTokens().then((data) => {   
          this.pdsList = data.result;
        }).catch((err) => {
            console.log(err);
        }).finally(() =>{
          store.dispatch('setLoading', false);
        });




      },

      computed: {
      },
      
      methods: {
      }
  }
</script>