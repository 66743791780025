import axios from 'axios';
import store from '../store';

export default class ApiRequest {


    doPost(url, form = {}, headers = {}) {
        return new Promise((resolve, reject) => {
            axios.post(
                url,
                form, {
                    headers: headers
                }
            ).then((response) => {
                store.dispatch('setLoading', false);
                resolve(response.data);
            }).catch((error) => {

                if (error.message === 'Network Error') {
                    reject('500');
                }

                store.dispatch('setLoading', false);
                if (error.response.status == 401) {

                    if (error.response.data.message && error.response.data.message.status && error.response.data.message.status == "9401") {
                        store.dispatch('refreshTokenExpired');
                    } else if (error.response.data.message != 'Invalid credentials.') {
                        store.dispatch('sessionExpired');
                    }
                }
                reject(error.response.status);
            });
        });
    }

    doGet(url, headers = {}) {

        return new Promise((resolve, reject) => {
            axios.get(
                    url, {
                        headers: headers
                    }
                ).then(response => {
                    store.dispatch('setLoading', false)
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        if (error.response.data.message && error.response.data.message.status && error.response.data.message.status == "9401") {
                            store.dispatch('refreshTokenExpired');
                        } else {
                            store.dispatch('sessionExpired');
                        }
                    }
                    store.dispatch('setLoading', false)
                    reject(error.response.status);
                });
        });

    }

}