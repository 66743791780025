<template>

    <div class="text-center spinnerLoader" v-if="this.$store.state.loading">
        
        <div class="spinner-border m-2" role="status"></div>

    </div>

</template>


<script>

export default {}

</script>