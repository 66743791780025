<template>
  <div id="app">
    <loginPopup 
      v-if="
        this.$store.state.sessionExpired===true &&
        this.$store.state.refreshTokenExpired===true && 
        $route.path != '/login' && 
        $route.path != '/reset'  && 
        $route.path != '/forgotten-password'  && 
        $route.path != '/'">
    </loginPopup>
    <router-view :key="$route.fullPath"></router-view>

    <footer>
      <footerCookies v-if="$store.state.accessToken && $store.state.user" />
    </footer>
  </div>
  
</template>


<script>
  import api from "./utils/api";
  import loginPopup from "./components/functionnals/loginPopup";
  import footerCookies from "./components/functionnals/footerCookies.vue";
  

  export default {
    name: 'app',

    components:{
        loginPopup,
        footerCookies
    },

    updated() {
      if(this.$store.state.sessionExpired){
        api.doLoginByRefreshToken().then((data) => {   
              this.$store.dispatch('saveToken', data.token);
              this.getUserInformations();
          }).catch((err) => {
             console.log("err : " + err);
          });
      }
    },

    methods: {

      getUserInformations: function(){

            this.apiHasError = false;
            this.apiErrorMessage = null;

            api.getUser().then((data) => {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('updateUser', data);
                this.$router.go();
            }).catch(() => {
                this.$store.dispatch('setLoading', false);
                this.apiErrorMessage = this.$i18n.t('_server_error');
                this.apiHasError = true;
            });
        }        
    }

  }
</script>


<style lang="scss">
  @import './assets/styles/global.scss';
</style>

