<template>
        <div class='cgu-popup col-md-6 col-lg-5 col-xl-4 col-11 rounded shadow shadow-sm center'>
            <spinner></spinner>
            <img src="../../assets/images/validate.png" width="50px" />
            <br><br>
            <h5> {{ title }}</h5>
            <p>
                {{ description }}
            </p>
            <div class="form-check form-cgu">
                <input type="checkbox" class="form-check-input" v-model="selected">
                <label class="form-check-label" >{{ this.$i18n.t('_authorise_service') }}</label>
                <br><br>
                <a @click="showCgu()"> {{ this.$i18n.t('_see_cgu') }}</a>
            </div>
            <div class="btn-cgu" :style="style" @click="selected ? acceptCgu() : null">{{ this.$i18n.t('_validate_activate_service') }}</div>
        </div>

</template>



<script>

import spinner from "./spinner";
import api from "../../utils/api";
import store from '../../store';

export default {
    
    props: {
        title: {type: String, required: true},
        description: {type: String, required: false}
    },

    components:{
        spinner
    },

    data: function(){
        return {
            selected: false
        }
    },
    computed: {
        style: function(){
                return (this.selected) ? { backgroundColor: '#9bcd41', fontWeight: 'bold', cursor: 'pointer', color: '#444444' } : null;
        },
    },

    methods:{
         //accept cgu => store in database
        acceptCgu() {
          api.acceptCgu().then((data) => {   
              store.dispatch('updateUser', data);
          }).catch((err) => {
              console.log(err);
          }).finally(() =>{
            store.dispatch('setLoading', false);
          });
        },

        showCgu: function(){
            this.$emit('show-cgu', true);
        }
    },
}
</script>