<template>
  <div>
    <b-modal v-model="modalShow" hide-header hide-footer id="bv-modal" size="lg">
      <div class="page-modal-content">
        <h3>{{title}}</h3>
        <div v-html="content" class="content-pagemodal"></div> 
      </div>
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal')">{{ this.$i18n.t('_close') }}</b-button>
    </b-modal>
  </div>
</template>

<script>

  export default {
    name: 'modal',
    
    props: {
      bus: {type: Object},
      title: {type: String},
      content: {type: String}
    },

    data() {
      return {
        modalShow: true
      }
    },

    methods: {
      showModal() {
        this.modalShow = true;
      }
    },
    
    mounted() {
      this.bus.$on('showModal', this.showModal)
    } 
  }
</script>