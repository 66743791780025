<template>
  <div>
    <template v-if="consumption && subscription.activated && !loading">
      <div class="blockInformation block-m-height block-x1 floatLeft">
        <div class="blockInformation-left">
          <h2 v-html="this.$i18n.t('_average_daily_consumption')"></h2>
          <span
            >{{
              consumption.consumption.averageVolume | roundResult | formatNumber
            }}
            L</span
          > / {{ this.$i18n.t("_day") }}
        </div>
        <div class="blockInformation-right">
          <img src="../../../assets/images/icons/calendar.png" />
        </div>
      </div>
      <div class="blockInformation block-m-height block-x1 floatLeft">
        <div class="blockInformation-left">
          <h2
            v-html="
              this.$i18n.t('_your_monthly_consumption', {
                month: this.$i18n.t('_month')[month],
              })
            "
          ></h2>
          <span
            >{{
              consumption.consumption.totalVolume | roundResult | formatNumber
            }}
            L</span
          >
        </div>
        <div class="blockInformation-right">
          <img src="../../../assets/images/icons/calendar2.png" />
        </div>
      </div>
      <div class="blockInformation block-m-height block-x1 floatLeft lastBloc">
        
        <template v-if="subscription.completed">
          <div class="blockInformation-left">
            <p class='description' v-if="subscriptionForParams.parameters.people > numberOfOccupantEquivalence">
              {{ this.$i18n.t('_consumption_state') }}
            </p>
            <p class='description' v-else-if="subscriptionForParams.parameters.people == numberOfOccupantEquivalence">
              {{ this.$i18n.t('_consumption_state_normal') }}
            </p>
            <p class='description' v-else>
               {{ this.$i18n.t('_consumption_bad_state') }} {{ numberOfOccupantEquivalence }} {{ this.$i18n.t('_persons') }}
            </p>

          </div>
          <div class="blockInformation-right">
            <img v-if="subscriptionForParams.parameters.people > numberOfOccupantEquivalence" 
              src="../../../assets/images/icons/parfaite-conso.png" />
            <img v-else-if="subscriptionForParams.parameters.people == numberOfOccupantEquivalence" 
              src="../../../assets/images/icons/moyenne-conso.png" />
            <img v-else 
              src="../../../assets/images/icons/bad-conso.png" />
          </div>
        </template>
        <template v-else>
          <div class="blockInformation-left width100" >
            <p class='description' v-html="this.$i18n.t('_profile_advice')"></p>
          </div>
        </template>

      </div>

      
      <div class="header-page mt-4">
       <!-- <h1 class="floatLeft">{{ $t("_alerts") }}</h1>-->
      </div>

      <!--
      <div class="blockInformation block-m-height block-x1 floatLeft">
        <div class="blockInformation-left">
          <h2 v-html="this.$i18n.t('_subscribe_to_alerts')"></h2>
          <span>1</span>
        </div>
        <div class="blockInformation-right">
          <img src="../../../assets/images/icons/red-alert.png" />
        </div>
      </div>
      -->

      <div class="blockInformation leakInformation block-x2 floatLeft mr-0">
        <div class="header">
          <div class="row pb-3">
            <div class="col-1">
              <img class="pt-2" src="../../../assets/images/icons/robinet.png" />
            </div>
            <div class="col-9 pl-0">
              <p class="header-title">
                {{ $t("_leak_meter") }}
              </p>
            </div>
            <div class="col-2 text-right" v-if='getMonthLeak() > 0' >
              <span class="pointer" v-b-tooltip.hover :title="leakTitle"> {{ getMonthLeak() | formatNumber}} L</span>
            </div>
          </div>
        </div>
        <div class="alert-content">
          <div class="alert-leak-gadget">
            <highcharts  v-if='getMonthLeak() > 0' :options="chartOptions"></highcharts>
            <p class="center leak-informations" v-else> {{ $t("_no_leak_detected") }}</p>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="subscription.activated === false">
        <activationModal :subscription="subscription"></activationModal>
      </div>
      <div
        v-if="subscription.activated && apiErrorMessage"
        role="alert"
        aria-live="polite"
        aria-atomic="true"
        class="alert alert-danger center">
        {{ apiErrorMessage }}
      </div>
      <div v-if="accountNotFound">
        <accountNotFoundModal :pds="subscription.pds"></accountNotFoundModal >
      </div>
    </template>
    <div class="center" v-if="loading">
      <div class="spinner-border m-2" role="status"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import api from "../../../utils/api";
import Utils from "../../../utils/Util";
import activationModal from "../activationModal";
import accountNotFoundModal from "../accountNotFoundModal";

export default {
  name: "ParticularDashboardInformations",

  components: {
    activationModal,
    accountNotFoundModal
  },

  props: {
    subscription: { type: Object, required: true },
    month: { type: String, required: true },
  },

  data() {
    return {
      consumption: null,
      apiHasError: null,
      apiErrorMessage: "",
      accountNotFound: false,
      loading: false,
      utils: new Utils(),
      subscriptionForParams: null,
      leakTitle: this.$i18n.t('_month_leak_volume')
    };
  },

  mounted() {
    if (this.subscription.activated) {
      this.subscriptionForParams = this.getSubscriptionById(this.subscription.id);
      this.getConsumption();
    }
  },
  computed: {
    ...mapGetters([
          'getSubscriptionById'
        ]),

    chartOptions: function () {
      return this.getChartOptions();
    },

    numberOfOccupantEquivalence: function () {
      return this.getNumberOfOccupantEquivalence();
    },
  },

  watch: {
    month: function () {
      this.getConsumption();
    },
  },

  filters: {
    roundResult: function (value) {
      if (!value) return "";
      return Math.round(value);
    },

    formatNumber: function (value) {
      if (value) {
        var val = "" + value;
        return val.replace(/(\d)(?=(?:\d{3})+(?:$))/g, "$1 ");
      }
    },
  },
  methods: {

    getLeakPercentage(){

      return (this.consumption.consumption.totalVolume > 0 ? Math.round(this.consumption.consumption.continuousVolume / this.consumption.consumption.totalVolume * 100) : 0);
    },

    getMonthLeak(){
      return (Math.round(this.consumption.consumption.continuousVolume));
    },

    getNumberOfOccupantEquivalence () {
      this.subscriptionForParams = this.getSubscriptionById(this.subscription.id);
      return Math.max(1, Math.round(this.consumption.consumptionEquivalence.regularVolumePeople));
    },

    getChartOptions() {
      return {
        credits: false,
        chart: {
          type: "solidgauge",
          marginTop: -20,
          backgroundColor: "#FAFBFC",
          events: {
            load: function () {
              var svg;
              svg = document.getElementsByTagName("svg");
              if (svg.length > 0) {
                var path = svg[0].getElementsByTagName("path");
                if (path.length > 1) {
                  path[0].setAttributeNS(null, "stroke-linejoin", "round");
                }
              }
            },
          },
        },
        title: {
          text: "",
        },
        tooltip: {
          enabled: false,
        },

        pane: [
          {
            startAngle: -90,
            endAngle: 90,
            background: [
              {
                outerRadius: "99%",
                innerRadius: "99%",
                backgroundColor: "#E0E3FA",
                borderWidth: 25,
                borderColor: "#E0E3FA",
                shape: "arc",
              },
              {
                outerRadius: '82%',
                innerRadius: '78%',
                backgroundColor: "#E0E3FA",
                borderWidth: 0,
                shape: "arc",
              },
            ],
          },
        ],

        yAxis: [
          {
            min: 0,
            max: 100,
            minorTickColor: "#FAFBFC",
            offset: -20,
            lineWidth: 0,
            tickAmount: 2,
            labels: {
                x: 0,
                y: 40,
                format: '{value}%',
                distance: 30,
                style: {
                    fontSize: 15,
                    color: '#3D466C'
                }
            },
            tickLength: 0,
            minorTickWidth: 10,
            minorTickLength: 20,
            endOnTick: false,
          },
        ],

        plotOptions: {
          solidgauge: {
            borderWidth: 25,
            linecap: "round",
            dataLabels: {
              y: 80,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },

        series: [
          {
            dashStyle: "longdash",
            borderColor: "#EB5757",
            data: [
              {
                color: "#EB5757",
                radius: "100%",
                innerRadius: "100%",
                y: this.getLeakPercentage(),
              },
            ],
            dataLabels: {
              y: -70,
              format:
                '<div style="text-align:center">' +
                '<span style="font-size:45px;color:#030F40">' +
                "{y}%" +
                "</span>" +
                "</div>",
            },
          },
        ],
      };
    },

    getConsumption() {
      this.loading = true;
      api
        .getConsumption(this.subscription.id, this.month)
        .then((data) => {
          this.consumption = data;
          this.loading = false;
        })
        .catch((status) => {
          this.consumption = null;
          this.apiHasError = true;
          this.loading = false;
          
           if(status == 404){
                this.accountNotFound = true;
            }else{
                this.apiErrorMessage = this.$i18n.t("_server_error");
            } 
        });
    },
  },
};
</script>