import Vue from 'vue'
import Vuex from 'vuex'
import router from '../utils/routes';
import lang from './modules/lang';
import subscription from './modules/subscription';
import page from './modules/page';

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        accessToken: null,
        refreshToken: null,
        user: null,
        error: '',
        loading: false,
        menuOpen: true,
        fromPath: null,
        sessionExpired: null,
        refreshTokenExpired: null,
        refresh: false
    },

    mutations: {

        updateAccessToken: (state, accessToken) => {
            state.accessToken = accessToken;
            state.sessionExpired = false;
        },

        updateRefreshToken: (state, refreshToken) => {
            state.refreshToken = refreshToken;
            state.refreshTokenExpired = false;
        },

        updateUser: (state, user) => {
            state.user = user;
        },

        logout: (state) => {
            state.accessToken = null;
            state.user = null;
            state.sessionExpired = null;
            state.refreshTokenExpired = null;
        },

        acceptCgu: (state) => {
            state.user.cguAcceptation = true;
        },

        setLoading: (state, loading) => {
            state.loading = loading;
        },

        saveError: (state, error) => {
            state.error = error;
        },

        goMenu: (state) => {
            state.menuOpen = !state.menuOpen;
        },

        storeFromPath: (state, payload) => {
            state.fromPath = payload;
        },
        sessionExpired: (state) => {
            state.sessionExpired = true;
        },
        refreshTokenExpired: (state) => {
            state.refreshTokenExpired = true;
        },
        refresh: (state, payload) => {
            state.refresh = payload;
        },
        initializeSideBare: (state, payload) => {
            if (payload === 'false') {
                state.menuOpen = false;
            } else {
                state.menuOpen = true;
            }
        },
    },

    actions: {
        initializeSideBare({ commit }, payload) {
            commit('initializeSideBare', payload);
        },
        saveToken({ commit }, payload) {
            localStorage.setItem('accessToken', payload);
            commit('updateAccessToken', payload);
        },

        saveRefreshToken({ commit }, payload) {
            localStorage.setItem('refreshToken', payload);
            commit('updateRefreshToken', payload);
        },

        setUser({ commit }, payload) {
            localStorage.setItem('user', JSON.stringify(payload));
            commit('updateUser', payload);
            router.push('/' + payload.client.name + '/home');
        },

        updateUser({ commit }, payload) {
            localStorage.setItem('user', JSON.stringify(payload));
            commit('updateUser', payload);
        },

        fetchAccessToken({ commit }) {
            commit('updateAccessToken', localStorage.getItem('accessToken'));
        },
        fetchRefreshToken({ commit }) {
            commit('updateRefreshToken', localStorage.getItem('refreshToken'));
        },

        fetchUser({ commit }) {
            commit('updateUser', JSON.parse(localStorage.getItem('user')));
        },

        logout({ commit }) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
            commit('logout');
            router.push('/login');
        },

        deleteSession({ commit }) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
            commit('logout');
        },

        setLoading({ commit }, payload) {
            commit('setLoading', payload);
        },

        saveError({ commit }, payload) {
            commit('saveError', payload);
        },

        goMenu({ commit }) {
            commit('goMenu');
        },

        storeFromPath({ commit }, payload) {
            commit('storeFromPath', payload);
        },

        sessionExpired({ commit }) {
            commit('sessionExpired');
        },

        refreshTokenExpired({ commit }) {
            commit('refreshTokenExpired');
        },

        refresh({ commit }, payload) {
            commit('refresh', payload);
        },
    },

    getters: {

        getUserTypeProfile: (state) => {
            const subscriptions = state.user.subscriptions;
            var result = Object.keys(subscriptions)
                .map(key => subscriptions[key])
                .filter(subscription => subscription.service != null);

            if (result.length > 0) {
                return result[0].service.name;
            }

            return;
        }
    },

    modules: {
        lang,
        subscription,
        page
    }

})