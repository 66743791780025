<template>
<div>
    <div class="Cookie Cookie-bottom Cookie-dark-lime" v-if="$store.state.user.cookie == null">
        <div class="width100">
            <p>{{ $t('_cookies_law_message') }}</p>
            <button class="btn btn-lg rounded btn-suez-green border-radius m-2" @click="acceptCoockies()"><span>{{ $t('_accept') }}</span></button>
            <button class="btn btn-lg rounded btn-suez-white border-radius m-2" @click="showCookiesContent=true"><span>{{ $t('_customize') }}</span></button>
        </div>
    </div>

      <cookies @hide-cookies-content="showCookiesContent=false" :showCookiesContent="showCookiesContent"/>
</div>
</template>


<script>

import api from "../../utils/api";
import Utils from "../../utils/Util";

import cookies from "./cookies.vue";

export default {
  name: 'FooterCookies',

  components:{
        cookies
    },

  data () {
    return {
          utils: new Utils(),
          showCookiesContent: false
    }
  },

  props: {
  },

  methods: {

      acceptCoockies: function (){
         
          let result = {
              audience: true
          };
        
        api.saveCookies(result).then((data) => {
            this.$store.dispatch('updateUser', data);
        }).catch((err) => {
            console.log(err); 
        });

      }
  },
}
</script>