
const langs = [
    'fr',
    'en'
];

const defaultLang = 'en';


export default {
    langs,
    defaultLang
  } 
