<template>
    <article>
            <link :href="appCss" rel="stylesheet">
            <div 
            ref="widgetAlarm"
            class="portailAutonome-alarm" 
            :data-project="this.$store.state.user.client.onconnectname"
            :data-length="pdsList.length" 
            :data-lang="this.$store.state.lang.lang"
            >
            </div>
    </article>

</template>


<script>
import Configs from "./../../../configs";
    export default {
        
        name: 'Alarm',

        props: {
            pdsList: {type: Array, required: false}
        },

        data: function(){
            return {
                appCss: '',
                isLoadedApp: false,
                isLoadedChunk: false,
            }
        },

        mounted() {

            var widget = this.$refs.widgetAlarm;
            
            this.pdsList.map(function(value, key) {
                var counter = parseInt(key) + 1;
                widget.setAttribute('data-svp' + counter, value.pds);
                widget.setAttribute('data-name' + counter, value.name);
                widget.setAttribute('data-token' + counter, value.token);
                widget.setAttribute('data-creationDate' + counter, value.creationDate);
                 widget.setAttribute('data-color' + counter, value.color);                

            }, widget);


            this.appCss = Configs.value('VUE_APP_ALARM_WIDGET_URL') + '/css/app.css';

            let appScript = Configs.value('VUE_APP_ALARM_WIDGET_URL') + '/js/app.js'

            
            this.$unloadScript(appScript)
            .then(() => {
                this.$loadScript(appScript).then(() => {
                    this.isLoadedApp = true;
                })
                .catch(() => {
                    this.isLoadedApp = false;
                });
            })
            .catch(() => {
                 this.$loadScript(appScript).then(() => {
                    this.isLoadedApp = true;
                })
                .catch(() => {
                    this.isLoadedApp = false;
                });
            });
        }
    }
</script>
